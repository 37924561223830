import React, { useState, useEffect } from "react";
import { getUserToken } from "../api/sessionInit";
import db from "../firebase/firebaseConfig";
//import { collection, addDoc,onSnapshot,doc,updateDoc } from "firebase/firestore";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
const ContextGlobal = React.createContext();

const ProviderGlobal = ({ children }) => {
  //Adding more params
  const [idleadCTX, changeLeadCTX] = useState(null);
  const [pageCTX, changePageCTX] = useState(null);
  const [products, changeProducts] = useState([
    {
      id: "item-1",
      name: "Seguro para tu auto",
      icon: "directions_car",
      title: "Cotizar seguro para autos",
      precio: "$3,000",
      tiposervicio: "auto",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Robo total", "RC por daños a terceros", "Defensa legal / jurídica", "Gastos médicos ocupantes"],
      image: "/images/autos-1.jpg",
      status: 1,
      innerpage: "seguro_autos",
    },
    {
      id: "item-2",
      name: "Seguro para motos",
      icon: "motorcycle",
      title: "Cotizar seguro para motocicletas",
      precio: "$3,000",
      tiposervicio: "motos",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/motos-1.jpg",
      status: 1,
      innerpage: "seguro_motos",
    },
    {
      id: "item-3",
      name: "Seguro para Pickup",
      icon: "local_shipping",
      title: "Cotizar seguro para pickup",
      precio: "$3,000",
      tiposervicio: "carga",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/carga-1.jpg",
      status: 1,
      innerpage: "seguro_pickup_particular",
    },
    {
      id: "item-4",
      name: "Seguro Médico",
      icon: "local_hospital",
      title: "Cotizar seguro Médico",
      precio: "$12,000",
      tiposervicio: "medico",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Amplia red de hospitales", "Beneficios a familiares directos", "Variedad en paquetes y coberturas"],
      image: "/images/medicos-1.jpg",
      status: 1,
      innerpage: "seguro_medico",
    },
    {
      id: "item-5",
      name: "Seguro Hogar",
      icon: "home",
      title: "Cotizar seguro de Hogar",
      precio: "$12,000",
      tiposervicio: "hogar",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Coberturas para dentro y fuera de casa", "Responsabilidad civil", "Para viviendas propias o rentadas"],
      image: "/images/hogar-1.jpg",
      status: 1,
      innerpage: "seguro_hogar",
    },
  ]);
  const [brandsList, setBrandsList] = useState([
    // { id: 1, active: 1, name: "Quálitas", img: "images/qualitas.svg" },
    // { id: 2, active: 1, name: "Sura", img: "images/sura.svg" },
    // { id: 3, active: 1, name: "Mapfre", img: "images/mapfre.svg" },
    // { id: 4, active: 1, name: "Metlife", img: "images/metlife.svg" },
    // { id: 5, active: 1, name: "Sura", img: "images/sura.svg" },
  ]);
  const [styleclass, changeClassStyle] = useState({
    products: products,
    brands: brandsList,
    urlimage: "",
    contratame: "Contratar el seguro a tu medida es muy sencillo conmigo. Contáctame y te ayudaré a encontrar la mejor opción para ti",
    cedula: "S2147QWRS",
    contitle: "Mucho gusto soy",
    condescription: "tu agente de seguros",
    conimage: "/images/man.jpg",
    show_image: true,
    block_title: "Aquí puede encontrar los principales ramos que trabajo",
    block_description: "Si no encuentras el seguro que necesitas, contáctame dando click en el botón de abajo",
    //brands: brandsList,
  });

  //This works fine
  const storeAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  const [stateCard, changeStateCard] = useState({
    iscomplete: false,
    step: 0,
  });

  const [hideWizard, changeWizard] = useState(false);

  const [dataAgnFb, changeDataAgnFb] = useState({});

  const setStepCard = (step, flag) => {
    changeStateCard({
      iscomplete: flag,
      step: step,
    });
  };

  const setDataFirebase = (data) => {
    changeDataAgnFb(data);
  };

  useEffect(() => {
    //console.log("Accediendo por primera vez...");
    //if(storeAgent.step != "undefined" && storeAgent.step != null){
    if (storeAgent.length !== 0) {
      //console.log("from context store Agent", storeAgent);
      changeStateCard(storeAgent);
      if (storeAgent.document !== undefined && storeAgent.document !== null) {
        onSnapshot(doc(db, "cp_agents", storeAgent.document), (doc) => {
          changeDataAgnFb(doc.data());
        });
      }
    }
  }, []);

  return (
    <ContextGlobal.Provider
      value={{
        setStepCard,
        storeAgent,
        stateCard,
        setDataFirebase,
        changeDataAgnFb,
        dataAgnFb,
        styleclass,
        hideWizard,
        changeWizard,
        idleadCTX,
        changeLeadCTX,
        pageCTX,
        changePageCTX
      }}
    >
      {children}
    </ContextGlobal.Provider>
  );
};

//Get token API

export { ContextGlobal, ProviderGlobal };
