import { URL_AUTH, URL_USER_DATA } from "../utils/constants";
import axios from "axios";
import { authFetch } from "../utils/fetch";

export async function getAccessToken() {
  try {
    let res = await axios.post(URL_AUTH, {
      Token: "k2q08aN+zAmcH5/dPMELrQ==",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMeApi(userToken, logout) {
  try {
    const url = URL_USER_DATA;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Token_Security: userToken,
      }),
    };
    const result = await authFetch(url, params, logout);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
