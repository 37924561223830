import React from "react";
import ReactDOM from "react-dom";
import App from "./components/index/App";
import Admin from "./components/admin/Admin";
import Service from "./components/agentweb/Service";
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
//import { HashRouter } from 'react-router-dom';
import { ProviderGlobal } from "./contexts/ContextGlobal";
import "./css/style.css";
import "./css/default.css";
//Initialize GA4
ReactGA.initialize("G-Y4H1TYP4Q2");

ReactDOM.render(
  <BrowserRouter>
    <ProviderGlobal>
      <App />
    </ProviderGlobal>
  </BrowserRouter>,
  document.getElementById("root")
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
  console.log("test");
};
