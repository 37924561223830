import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { requestPremiumApi } from "../../api/Admin/user";

export default function PremiumLegal() {
  const { user, premium, setPremium } = useAuth();
  const userId = user.IdVend;
  const [aceptTerms, setAceptTerms] = useState(false);

  /*APi premium*/
  const addPremium = async () => {
    const response = await requestPremiumApi(user.IdVend);
    if (response.Success == true) {
      console.log("ya eres premium");
      setPremium(true);
    } else {
      console.log("hubo un problema");
    }
  };

  /*fin APi premium*/

  const handleInput = (e) => {
    if (e.target.checked) {
      setAceptTerms(true);
    } else {
      setAceptTerms(false);
    }
  };

  return (
    <div id="modal-legal-premium" className="modal">
      <div className="modal-content">
        <div className="flex-center-alw"></div>
        <a href="#" className="modal-close top-left">
          Cerrar
        </a>
        <h5 className="st text-center">Terminos y condiciones</h5>

        <div className="row mid-pad">
          <p className="small-txt text-justify">
            CLICK SEGUROS INC, S.A. DE C.V. con domicilio en Ejército Nacional No. 1112 Piso 1,Col.Los Morales Polanco, C.P.11510,enla Ciudadde MéxicoDistritoFederal,es una sociedad comprometida con
            la protección de sus datos personales, al ser responsable de su uso, manejo y confidencialidad;en tal virtud, es importante informarle que los datos personales que recabamos sobre Usted
            son necesarios para verificar y confirmar su identidad; administrar y operar los servicios y productos que solicita o contrata con nosotros, así como para cumplir con las obligaciones
            derivadas de la relación jurídica o de prestación de servicios existente entre Usted como titular de los datos personales y CLICK SEGUROS INC, S.A. DE C.V. Por ello, los datos personales
            que nos sean proporcionados, incluyendo los sensibles, que actualmente o en el futuro obren en posesión de nuestra sociedad, serán manejados, tratados y/o utilizados bajo los principios de
            licitud, consentimiento, calidad, información, proporcionalidad y responsabilidad, comprometiéndonos a observar y cumplir los citados principios, así como a no vender, alquilar, compartir
            o divulgar su información personal con fines ilícitos o contrarios a los fines para lo cual fueron proporcionados, salvo los casos excepcionales señalados en la Ley Federal de Protección
            de Datos Personales en Posesión de Particulares y su respectivo Reglamento o de prestación de servicios existente entre Usted como titular de los datos personales y CLICK SEGUROS INC, S.A.
            DE C.V. Por ello, los datos personales que nos sean proporcionados, incluyendo los sensibles, que actualmente o en el futuro obren en posesión de nuestra sociedad, serán manejados,
            tratados y/o utilizados bajo los principios de licitud, consentimiento, calidad, información, proporcionalidad y responsabilidad, comprometiéndonos a observar y cumplir los citados
            principios, así como a no vender, alquilar, compartir o divulgar su información personal con fines ilícitos o contrarios a los fines para lo cual fueron proporcionados, salvo los casos
            excepcionales señalados en la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su respectivo Reglamento
          </p>
          <div className="text-center mt-5">
            <label>
              <input name="group1" type="checkbox" checked={aceptTerms === true ? "checked" : ""} onChange={(e) => handleInput(e)} />
              <span>ACEPTO TERMINOS Y CONDICIONES</span>
            </label>
          </div>
          <div className="mt-5 text-center ">
            <button className="waves-effect sq-btn darken-1 white-text modal-close" onClick={addPremium} disabled={!aceptTerms}>
              Comencemos
            </button>
          </div>
          <div className="text-center">
            <button className="gh-btn mt-3 modal-trigger modal-close">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
