import React, { useEffect, useState } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import FormDatosContacto from "./FormDatosContacto";
import FormAgentCard from "./FormAgentCard";
import axios from "axios";
const FormBudgetVehiculo = ({ formStepContact, changeFSC, changeCotizar, styleclass, dataAgnFb, subdomain, firebaseUser, publicToken }) => {
  //const concept = "Seguro Vehículo";
  const concept = { name: "Seguro Vehículo", codename: "segurovehiculo" };
  const url_step_1 = "https://ws.developmentservices.com.mx/Api3.0/ApiLoginRest/api/login/authenticate/";
  const urlModels = "https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Modelos";

  /* Selects */
  const [modeloOpt, modeloState] = useState([{ Texto: "Cargando", Valor: "" }]); //Universal
  /* Values */
  /*
        const[modVal, modState]  = useState("");
        const[marVal, marState]  = useState("");
        const[tranVal, traState]  = useState("");
        const[verVal, verState]  = useState("");
    */
  const [vehicleR, changeVehicleR] = useState({
    unidad: "automovil",
    tipounidad: 1,
    marca: "",
    modelo: "",
    transmision: "",
    version: "",
    cve_click: "",
    descripcion: "",
  });

  const [contactMethod, setContactMethod] = useState({
    name: "",
    email: "",
    phone: "",
    contactby: "",
  });

  const [forms, changeForms] = useState(1);

  //call the api modelos, marcas, version, transmision
  const [token, tokenState] = useState({});

  const login = () => {
    axios
      .post(url_step_1, { Token: "k2q08aN+zAmcH5/dPMELrQ==" })
      .then(function (response) {
        tokenState(response.data);
        fillModelos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fillModelos = (dataToken) => {
    const options = { Authorization: "Click " + dataToken.Token };
    axios
      .post(urlModels, { Token: "k2q08aN+zAmcH5/dPMELrQ==", tipo: vehicleR.tipounidad }, { headers: options })
      .then(function (response) {
        let A = [{ Texto: "Seleccionar", Valor: "" }];
        let B = response.data;
        const result = A.concat(B.filter((bo) => A.every((ao) => ao.Valor != bo.Valor)));
        modeloState(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    M.AutoInit();
    login();
  }, []);

  //This is another view
  const FormMain = ({ vehicleR, changeVehicleR, changeForms, style, token }) => {
    //const urlModels = 'https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Modelos';
    const urlMarcas = "https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Marcas";
    const urlVersiones = "https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Versiones";
    const urlTransmisiones = "https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Transmisiones";
    const urlDescripciontes = "https://ws.developmentservices.com.mx/Api3.0/ApiCatalogo/api/Catalogo/Recupera_Descripciones";

    const tokenUp = token;

    const [vehicle, changeVehicle] = useState(vehicleR);
    /*Local */
    const [marcaOpt, marcaState] = useState([{ Texto: "Seleccionar", Valor: "" }]);
    const [transOpt, transState] = useState([{ Texto: "Seleccionar", Valor: "" }]);
    const [versOpt, versState] = useState([{ Texto: "Seleccionar", Valor: "" }]);
    const [descOpt, descState] = useState([{ Texto: "Seleccionar", Valor: "" }]);

    const fillMarcas = (valuemod) => {
      const options = { Authorization: "Click " + tokenUp.Token };
      axios
        .post(urlMarcas, { Token: "k2q08aN+zAmcH5/dPMELrQ==", Tipo: vehicle.tipounidad, Modelo: valuemod }, { headers: options })
        .then(function (response) {
          //let result = response.data;
          //console.log("Response Marcas",result);
          let A = [{ Texto: "Seleccionar", Valor: "" }];
          let B = response.data;
          const result = A.concat(B.filter((bo) => A.every((ao) => ao.Valor != bo.Valor)));
          marcaState(result);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const fillVersiones = (value) => {
      //console.log("datos acumulados",vehicle);
      const options = { Authorization: "Click " + tokenUp.Token };
      axios
        .post(urlVersiones, { Token: "k2q08aN+zAmcH5/dPMELrQ==", Tipo: vehicle.tipounidad, Modelo: vehicle.modelo, Marca: value }, { headers: options })
        .then(function (response) {
          //let result = response.data;
          //console.log("Response Versiones",result);
          let A = [{ Texto: "Seleccionar", Valor: "" }];
          let B = response.data;
          const result = A.concat(B.filter((bo) => A.every((ao) => ao.Valor != bo.Valor)));
          versState(result);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const fillTransmisiones = (value) => {
      const options = { Authorization: "Click " + tokenUp.Token };
      axios
        .post(
          urlTransmisiones,
          {
            Token: "k2q08aN+zAmcH5/dPMELrQ==",
            Tipo: vehicle.tipounidad,
            Modelo: vehicle.modelo,
            Marca: vehicle.marca,
            Version: value,
          },
          { headers: options }
        )
        .then(function (response) {
          //let result = response.data;
          //console.log("Response Versiones",result);
          let A = [{ Texto: "Seleccionar", Valor: "" }];
          let B = response.data;
          const result = A.concat(B.filter((bo) => A.every((ao) => ao.Valor != bo.Valor)));
          transState(result);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const fillDescripciones = (value) => {
      const options = { Authorization: "Click " + tokenUp.Token };
      axios
        .post(
          urlDescripciontes,
          {
            Token: "k2q08aN+zAmcH5/dPMELrQ==",
            Tipo: vehicle.tipounidad,
            Modelo: vehicle.modelo,
            Marca: vehicle.marca,
            Version: vehicle.version,
            Transmision: value,
          },
          { headers: options }
        )
        .then(function (response) {
          //let result = response.data;
          //console.log("Response Versiones",result);
          let A = [{ Texto: "Seleccionar", Valor: "" }];
          let B = response.data;
          const result = A.concat(B.filter((bo) => A.every((ao) => ao.Valor != bo.Valor)));
          descState(result);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const handleInput = (tipo, value, elem = null) => {
      const v_marca = document.getElementById("v_marca");
      const v_version = document.getElementById("v_version");
      const v_transmision = document.getElementById("v_transmision");
      const v_descripcion = document.getElementById("v_descripcion");
      if (tipo === "modelo") {
        changeVehicle({
          ...vehicle,
          modelo: value,
        });
        v_marca.value = "";
        fillMarcas(value);
      } //OK

      if (tipo === "marca") {
        changeVehicle({
          ...vehicle,
          marca: value,
        });
        v_version.value = "";
        fillVersiones(value);
      } //OK

      if (tipo === "version") {
        changeVehicle({
          ...vehicle,
          version: value,
        });
        v_transmision.value = "";
        fillTransmisiones(value);
      } //OK

      if (tipo === "transmision") {
        changeVehicle({
          ...vehicle,
          transmision: value,
        });
        v_descripcion.value = "";
        fillDescripciones(value);
      }

      if (tipo === "descripcion") {
        let text = elem.options[elem.selectedIndex].text;
        changeVehicle({
          ...vehicle,
          descripcion: text,
          cve_click: value,
        });
        //console.log("elemento select",text);
        //This fill nothing
      }
    };
    const onSubmitForm = () => {
      console.log("valor de vehicle =>", vehicle);
      if (vehicle.modelo == "") {
        M.toast({ html: "Debes seleccionar un modelo por favor." });
        return false;
      }
      if (vehicle.marca == "") {
        M.toast({ html: "Debes seleccionar una marca por favor." });
        return false;
      }
      if (vehicle.version == "") {
        M.toast({ html: "Debes seleccionar una versión por favor." });
        return false;
      }
      if (vehicle.transmision == "") {
        M.toast({ html: "Debes seleccionar una transmisión por favor." });
        return false;
      }
      if (vehicle.descripcion == "") {
        M.toast({ html: "Debes seleccionar una descripción por favor." });
        return false;
      }

      //return false;
      /*
      if(v_marca.value == ""){
        
      }
      if(v_version.value == ""){
       
      }
      if(v_transmision.value == ""){
        
      }
      if(v_descripcion.value == ""){
        
      }
      */

      changeVehicleR(vehicle);
      //changeFSC({ formStep: 2});
      changeForms(2);
    };
    useEffect(() => {
      changeVehicle({
        ...vehicle,
        modelo: "",
        marca: "",
        version: "",
        transmision: "",
        descripcion: "",
      });

      //console.log(":::::Data we are here again:::::", vehicle);
    }, []);

    return (
      <div className={`row ${style}`}>
        <div className="col m12 s12 ">
          <h3 className="pl-3 st mb-0">¿Qué tipo de vehículo quieres cotizar?</h3>
        </div>
        <div className="col s12 m12 p-3">
          <div className="card radius-card no-padding">
            <div className="card-content no-padding">
              <div className="row">
                <div className="col s12 m12">
                  <div className="card radius-card z-depth-2 mt-0 p-4">
                    <ul className="list-inline sub-select flex-c">
                      <li>
                        <div className="center-align">
                          <button
                            className="z-depth-2"
                            onClick={() => {
                              changeVehicle({
                                ...vehicle,
                                unidad: "automovil",
                                tipounidad: 1,
                              });
                            }}
                          >
                            <i className="small material-icons circ-b">directions_car</i>
                          </button>
                          <div className="mt-2">Auto / SUV</div>
                        </div>
                      </li>
                      <li>
                        <div className="center-align">
                          <button
                            className="z-depth-2"
                            onClick={() => {
                              changeVehicle({
                                ...vehicle,
                                unidad: "motocicleta",
                                tipounidad: 2,
                              });
                            }}
                          >
                            <i className="small material-icons circ-b">motorcycle</i>
                          </button>
                          <div className="mt-2">Motocicleta</div>
                        </div>
                      </li>
                      <li>
                        <div className="center-align">
                          <button
                            className="z-depth-2"
                            onClick={() => {
                              changeVehicle({
                                ...vehicle,
                                unidad: "pick-up",
                                tipounidad: 3,
                              });
                            }}
                          >
                            <i className="small material-icons circ-b">local_shipping</i>
                          </button>
                          <div className="mt-2">Pick up</div>
                        </div>
                      </li>
                      {/* <li>
                        <div className="center-align">
                          <button
                            className="z-depth-2"
                            onClick={() => {
                              changeVehicle({
                                ...vehicle,
                                unidad: "pick-up de carga",
                                tipounidad: 4,
                              });
                            }}
                          >
                            <i className="small material-icons circ-b">rv_hookup</i>
                          </button>
                          <div className="mt-2">Pick up de carga</div>
                        </div>
                      </li>
                      <li>
                        <div className="center-align">
                          <button
                            className="z-depth-2"
                            onClick={() => {
                              changeVehicle({
                                ...vehicle,
                                unidad: "equipo pesado",
                                tipounidad: 5,
                              });
                            }}
                          >
                            <i className="small material-icons circ-b">directions_bus</i>
                          </button>
                          <div className="mt-2">Equipo pesado</div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="ph-10">
                  <div className="col s12 m12 mt-5">
                    <div className="col s12 m4 mar">
                      <label htmlFor="v_modelo">Modelo</label>
                      {/* <input 
                                                className="rounded-input" 
                                                id="v_modelo"
                                                type="text"
                                                value={vehicle.modelo}
                                                onChange={(e)=>{handleInput("modelo", e.target.value)}}
                                            /> */}
                      <select
                        className="browser-default"
                        id="v_modelo"
                        onChange={(e) => {
                          handleInput("modelo", e.target.value);
                        }}
                      >
                        {modeloOpt.map((item, index) => {
                          return (
                            <option key={index} value={item.Valor}>
                              {item.Texto}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col s12 m4">
                      <label htmlFor="v_marca">Marca</label>
                      {/* <input 
                                                className="rounded-input" 
                                                id="v_marca" 
                                                type="text"
                                                value={vehicle.marca}
                                                onChange={(e)=>{handleInput("marca", e.target.value)}}
                                            /> */}
                      <select
                        className="browser-default"
                        id="v_marca"
                        onChange={(e) => {
                          handleInput("marca", e.target.value);
                        }}
                      >
                        {marcaOpt.map((item, index) => {
                          return (
                            <option key={index} value={item.Valor}>
                              {item.Texto}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col s12 m4">
                      <label htmlFor="v_version">Versión</label>
                      {/* <input 
                                                className="rounded-input" 
                                                id="v_version"
                                                type="text"
                                                value={vehicle.version}
                                                onChange={(e)=>{handleInput("version", e.target.value)}}
                                            /> */}
                      <select
                        className="browser-default"
                        id="v_version"
                        onChange={(e) => {
                          handleInput("version", e.target.value);
                        }}
                      >
                        {versOpt.map((item, index) => {
                          return (
                            <option key={index} value={item.Valor}>
                              {item.Texto}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col s12 m12 mt-5">
                    <div className="col s12 m4">
                      <label htmlFor="v_transmision">Transmisión</label>
                      {/* <input 
                                                className="rounded-input" 
                                                id="v_transmision"
                                                type="text"
                                                value={vehicle.transmision}
                                                onChange={(e)=>{handleInput("transmision", e.target.value)}}
                                            /> */}
                      <select
                        className="browser-default"
                        id="v_transmision"
                        onChange={(e) => {
                          handleInput("transmision", e.target.value);
                        }}
                      >
                        {transOpt.map((item, index) => {
                          return (
                            <option key={index} value={item.Valor}>
                              {item.Texto}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col s12 m4">
                      <label htmlFor="v_descripcion">Descripción</label>
                      {/* <input 
                                                className="rounded-input" 
                                                id="v_transmision"
                                                type="text"
                                                value={vehicle.transmision}
                                                onChange={(e)=>{handleInput("transmision", e.target.value)}}
                                            /> */}
                      <select
                        className="browser-default"
                        id="v_descripcion"
                        onChange={(e) => {
                          handleInput("descripcion", e.target.value, e.target);
                        }}
                      >
                        {descOpt.map((item, index) => {
                          return (
                            <option key={index} value={item.Valor}>
                              {item.Texto}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col s12 m12 text-center pt-4 pb-4">
                    <button className={styleclass.bgPrimary + " cotiza"} onClick={onSubmitForm}>
                      Enviar información
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FormRes = ({ vehicle, changeForms, style }) => {
    return (
      <div className={`col s12 m12 ${style}`}>
        <div className="card radius-card p-3">
          <h3 className="st">
            Estas cotizando{" "}
            <button
              className="btn-borblub"
              onClick={() => {
                changeForms(1);
              }}
            >
              Editar información
            </button>
          </h3>
          <p>
            <span>{vehicle.marca}</span> | <span>{vehicle.modelo}</span> | <span>{vehicle.transmision}</span> | <span>{vehicle.version}</span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <FormMain style={forms === 1 ? "show" : "hidden"} vehicleR={vehicleR} changeVehicleR={changeVehicleR} changeForms={changeForms} token={token} />
      <FormRes style={forms === 2 ? "show" : "hidden"} vehicle={vehicleR} changeForms={changeForms} />

      {/* AQUI SE DEBE HACER EL AJUSTE */}
      <FormDatosContacto
        style={forms === 2 ? "show" : "hidden"}
        contactMethod={contactMethod}
        setContactMethod={setContactMethod}
        changeForms={changeForms}
        data={vehicleR}
        concept={concept}
        styleclass={styleclass}
        subdomain={subdomain}
        firebaseUser={firebaseUser}
        publicToken={publicToken}
        dataAgnFb={dataAgnFb}
      />

      {/* ESTE SE MUESTRA AL FINAL OCULTA LO DEMAS */}
      <FormAgentCard
        style={forms === 3 ? "show" : "hidden"}
        changeFSC={changeFSC}
        changeForms={changeForms}
        changeCotizar={changeCotizar}
        formStepContact={formStepContact}
        styleclass={styleclass}
        dataAgnFb={dataAgnFb}
      />
      {/* {(formStepContact.formStep === 1) ? <FormMain vehicleR={vehicleR} changeVehicleR={changeVehicleR}></FormMain> : ""}
            {(formStepContact.formStep === 2) ? <FormRes vehicle={formStepContact.data}></FormRes> : ""} */}
    </>
  );
};

export default FormBudgetVehiculo;
