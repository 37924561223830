import React, { useContext, useEffect } from "react";
import { Route, Switch, useParams, Link } from "react-router-dom";
import { HashLink as Linke } from "react-router-hash-link";
import Service from "../agentweb/Service";
import Slider from "react-slick";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const Services = ({ nameurl, styleclass, changeFlag, isproduction, changeCotizar, firebaseUser, subdomain }) => {
  
  const { pageCTX, changePageCTX } = useContext(ContextGlobal);

  let { innerpage } = useParams();
  const { products } = styleclass;
  const activeProducts = products.filter((product) => product.status == 1);
  const settings = {
    className: "carousel-screenshots",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: activeProducts.length < 3 ? activeProducts.length : 3,
    slidesToScroll: 1,
    swipeToSlider: true,
    centerPadding: 0,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: activeProducts.length < 3 ? activeProducts.length : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const Child = (props) => {
    const { nameurl } = props;
    let { innerpage } = useParams();

    return (
      <>
        <style jsx global>{`
          #blockHeader,
          #blockBudget,
          #blockNoFooter,
          #blockServicesInner {
            display: none;
          }
        `}</style>
        <div className="row col m12 s12 center">
          <Service innerpage={innerpage} nameurl={nameurl}></Service>
        </div>
      </>
    );
  };
  const BlockEdit = () => {
    return (
      <div className="edit-frame">
        <button
          className="edit-btn sc-btn"
          onClick={() => {
            changeFlag({ isservice: true, iscard: false, iscedula: false });
          }}
        >
          {" "}
          Editar{" "}
        </button>
      </div>
    );
  };
  return (
    <div>
      <div className="container-fluid white" id="blockServicesInner">
        <div className="flex-st edit">
          <div className="slider-info flex-c">
            {/* Este botón de editar solo aparece cuando se personaliza el sitio */}
            {!isproduction && styleclass.site_is_edited ? <BlockEdit /> : ""}
            <div className="first-box flex-alw">
              <div>
                <h5>{styleclass.block_title}</h5>
                <p>{styleclass.block_description}</p>
                <Linke to="#contacto">
                  <button className="btn-borblu mt-4">Solicita una cotización de lo que necesites</button>
                </Linke>
              </div>
            </div>
          </div>
          <div className="slider-container ">
            <Slider {...settings}>
              {products.map((item, index) => {
                const detalles = item.detalles;
                if (item.status === 1) {
                  return (
                    <div className="caja" key={index}>
                      <div className="c-tit">{item.title}</div>
                      <div className="price">
                        desde <strong>{item.precio}</strong>
                      </div>
                      <div className="sma">{item.cobertura}</div>
                      {styleclass.show_image ? (
                        // <img src={styleclass.imagesproduct} className="responsive-img" />
                        <img src={item.image} className="responsive-img" />
                      ) : (
                        ""
                      )}
                      {detalles.map((d, ix) => {
                        return (
                          <div className="list-pad" key={ix}>
                            <div className="list-txt">
                              <span className="list-circle icon-check"></span>
                              {d}
                            </div>
                          </div>
                        );
                      })}
                      <div className="adj"></div>
                      <div className="fix-position">
                        <div
                          href="!#"
                          className={" btn-service  btn-blub " + styleclass.btn}
                          onClick={() => {
                            changePageCTX(null);
                            changeCotizar(item.tiposervicio);

                          }}
                        >
                          Cotizar
                        </div>
                        <div className="mt-2">
                          {nameurl == undefined ? <Link to={`/${subdomain}/${item.innerpage}`}>Más información</Link> : <Link to={`/${nameurl}/${item.innerpage}`}>Más información</Link>}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Slider>
          </div>
        </div>
      </div>
      {nameurl == undefined ? (
        <Switch>
          <Route path={`/${subdomain}/:innerpage`} children={<Child nameurl={nameurl} />} />
        </Switch>
      ) : (
        <Switch>
          <Route path={`/${nameurl}/:innerpage`} children={<Child nameurl={nameurl} />} />
        </Switch>
      )}
    </div>
  );
};

export default Services;
