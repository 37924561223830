import React from 'react';
import { collection, addDoc, onSnapshot, doc, updateDoc, where, query, getDocs } from "firebase/firestore";
import db from '../firebase/firebaseConfig';
export async function updateLead(subdomain) {
    try {
        const q = query(collection(db, "cp_agents"), where("agent_url", "==", subdomain));
        const querySnapshot = await getDocs(q);
        const userFB = querySnapshot.docs[0].data();
        const userIdFB = querySnapshot.docs[0].id;

        console.log("Document ID ~~~~  ", userIdFB);
        console.log("Data User Firebase ~~~~", userFB);
        
        /* update the data on the doc */
        
            const userRef = doc(db, "cp_agents", userIdFB);
            updateDoc(userRef, {
                leads_counter: parseInt(userFB.leads_counter) + 1
            }).then(() => {
                    console.log("..:::Se ha añadido el LEAD:::..", userFB.leads_counter);
            }).catch((error) => {
                    console.error("Error updating document: ", error);
            });
        
    } catch (error) {
        console.log(error);
    }

}