import React, { useEffect, useContext, useState } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import useAuth from "../../hooks/useAuth";
import M from "materialize-css/dist/js/materialize.min.js";
import axios from "axios";
import { Carousel } from "materialize-css";
import { updateLead } from "../../api/updateLead";

const FormDatosContacto = ({ contactMethod, setContactMethod, style, changeForms, data, concept, styleclass, firebaseUser, publicToken, subdomain, dataAgnFb }) => {
  const { user } = useAuth();
  const { hideWizard, changeWizard } = useContext(ContextGlobal);
  //const leadUrlAdd = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/add_lead";
  const leadUrlAdd = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";
  const tokenData = localStorage.getItem("tokenData") ? JSON.parse(localStorage.getItem("tokenData")) : publicToken;
  const clicUser = user.IdVend !== 0 ? user : firebaseUser;
  const [subdomainFB, chSubdomainFB] = useState(dataAgnFb.agent_url);

  //console.log("URL sitio web :::",subdomainFB);

  const addLead = (tokenData, contactMethod, vehicle, concept) => {
    console.log(tokenData);
    //Update the firebase first

    const options = { Authorization: "Click " + tokenData };

    let typedata = { concept: concept.name, data: vehicle };

    let car = {
      marca: "ACURA",
      modelo: "2022",
      version: "MDX",
      transmision: "AUT",
      descripcion: "AA ACURA MDX ADVANCE 5P V6 3.5L ABS NAVI QC AC AWD AUT. 07 OCUP.",
      cve_click: "AUESACRAMDX0MD0MDX-001",
      idsramo: 1,
    };
    if (concept.codename === "segurovehiculo") {
      //car.marca =
      car.marca = vehicle.marca;
      car.modelo = vehicle.modelo;
      car.version = vehicle.version;
      car.transmision = vehicle.transmision;
      car.descripcion = vehicle.descripcion;
      car.cve_click = vehicle.cve_click;
      /*
                "IDSRamo": 1, "Nombre": "Autos Individual",
                "IDSRamo": 2, "Nombre": "Motocicletas",
                "IDSRamo": 3, "Nombre": "Safetrans",    
                "IDSRamo": 4, "Nombre": "Gastos Medicos",
                "IDSRamo": 5, "Nombre": "Hogar",   
                */
      car.idsramo = 1;
      if (vehicle.tipounidad === 1) {
        car.idsramo = 1;
      }
      if (vehicle.tipounidad === 2) {
        car.idsramo = 2;
      }
      if (vehicle.tipounidad === 3 || vehicle.tipounidad === 4 || vehicle.tipounidad === 5) {
        car.idsramo = 3;
      }
      //typedata = {}
    }
    if (concept.codename === "seguromedico") {
      car.idsramo = 4;
    }
    if (concept.codename === "segurohogar") {
      car.idsramo = 5;
    }

    let contact = { whats: "", email: "", phone: "" };
    contact.whats = contactMethod.contactbywhatsapp;
    contact.email = contactMethod.contactbyemail;
    contact.phone = contactMethod.contactbyphone;
    //console.log(clicUser);
    //Debemos obtener el id del vendedor from firebase
    axios
      .post(
        leadUrlAdd,
        {
          Token: "k2q08aN+zAmcH5/dPMELrQ==",
          TypeProcess: "2",
          Params: {
            IDVend: clicUser.IdVend,
            IDPortal: "ABCDEF0123",
            IDSRamo: car.idsramo,
            Cve_Vend: clicUser.Clave, //changed by Aldo use tu be Cve_Vend: clicUser.IdCont
            ApellidoP: "--",
            ApellidoM: "--",
            Nombre: contactMethod.name,
            FechaNac: "01/01/1950",
            Edad: 18,
            RFC: "NOREQUESTED",
            Sexo: 0,
            Email: contactMethod.email,
            Telefono: contactMethod.phone,
            Celular: contactMethod.phone,
            ContWhats: contact.whats,
            ContEmail: contact.email,
            ContTel: contact.phone,
            Obs: "", //JSON.stringify(typedata),
            CPostal: "00000",
            Modelo: car.modelo,
            Marca: car.marca,
            Version: car.version,
            Transmision: car.transmision,
            Descripcion: car.descripcion,
            Cve_Click: car.cve_click,
            PlanCobertura: "1",
            Detalle: JSON.stringify(typedata),
            /* == */
            /*
                IDVend: "1",
                IDPortal: "ABCDEF0123",
                IDSRamo: "1",
                Cve_Vend: "00847",
                ApellidoP: "Mercado",
                ApellidoM: "Morales",
                Nombre: "Juan Carlos",
                FechaNac: "17/03/1983",
                Edad: 38,
                RFC: "MEMJ830317",
                Sexo: 0,
                Email: "carlos.mercado@grupobituaj.com.mx",
                Telefono: "5544332211",
                Celular: "5544332211",
                Referido: "Javier Mitrani",
                Obs: "Cliente recomendado por otro cliente",
                CPostal: "53840",
                Modelo: "2022",
                Marca: "ACURA",
                Version: "MDX",
                Transmision: "AUT",
                Descripcion: "AA ACURA MDX ADVANCE 5P V6 3.5L ABS NAVI QC AC AWD AUT. 07 OCUP.",
                Cve_Click: "AUESACRAMDX0MD0MDX-001",
                PlanCobertura: "1",
                Detalle: ""
                */
          },
        },
        {
          headers: options,
        }
      )
      .then(function (response) {
        console.log("Response add Lead => ", response.status, response.data);
        //showComponent

        (async () => {
          await updateLead(subdomainFB);
        })();
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

          const { request } = error;
          //const { XMLHttpRequest} = request; // take everything but 'request'

          if (request.status === 0) {
          }
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });
  };

  const CardContact = ({ contactMethod, setContactMethod, vehicleR, concept }) => {
    const [tmpu, changetmpu] = useState(contactMethod);
    useEffect(() => {
      //console.log("los datos actuales", tmpu);
      //console.log("datos de la unidad",vehicleR)
    }, [tmpu]);

    const phoneValidate = (numero) => {
      let nospace = numero.replace(/\s/g, "");
      let nonumbers = nospace.replace(/[^0-9]+/g, "");
      return nonumbers;
    };

    const emailClean = (email) => {
      return email.replace(/\s/g, "");
    };

    const emailFormat = (email) => {
      return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      //Mandar la información
      //console.log("token from session, form contact ", tokenData);
      if (tmpu.name == "") {
        M.toast({ html: "Escribe un nombre por favor." });
        return false;
      }
      if (tmpu.phone == "") {
        M.toast({ html: "Escribe un teléfono por favor." });
        return false;
      }
      if (tmpu.email == "") {
        M.toast({ html: "Escribe un correo por favor." });

        return false;
      } else {
        let resultEmail = emailFormat(tmpu.email);
        if (resultEmail == null) {
          M.toast({ html: "Ingresa un email válido" });
          return false;
        }
        //console.log("Resultados del formato email",);
      }

      addLead(tokenData, tmpu, vehicleR, concept);
      //e.target.disabled = false;
      //return false; //Quitar despues de las pruebas
      changeForms(3);
      changeWizard(true);
      //console.log("datos del objeto contactar por", tmpu);

      //return true;
    };

    return (
      <div className={`row ${style}`}>
        <div className="col s12 m12">
          <div className="card radius-card p-3">
            <h3 className="st">Datos de contacto</h3>
            <p className="mb-5">Proporciona tu contacto para poder contactarte y terminar los últimos detalles</p>
            <form>
              <div className="row">
                <div className="col s12 m4">
                  <label htmlFor="d_nombre">Nombre</label>
                  <input
                    className="rounded-input"
                    id="d_nombre"
                    type="text"
                    onChange={(e) => {
                      changetmpu({
                        ...tmpu,
                        name: e.target.value,
                      });
                    }}
                    value={tmpu.name}
                  />
                </div>
                <div className="col s12 m4">
                  <label htmlFor="d_telefono">Teléfono</label>
                  <input
                    className="rounded-input"
                    id="d_telefono"
                    type="text"
                    onChange={(e) => {
                      changetmpu({
                        ...tmpu,
                        phone: phoneValidate(e.target.value),
                      });
                    }}
                    value={tmpu.phone}
                    maxLength={10}
                  />
                </div>
                <div className="col s12 m4">
                  <label htmlFor="d_mail">Mail</label>
                  <input
                    className="rounded-input"
                    id="d_mail"
                    type="email"
                    onChange={(e) => {
                      changetmpu({
                        ...tmpu,
                        email: e.target.value,
                      });
                    }}
                    value={tmpu.email}
                    //required={true}
                  />
                </div>
                <div className="col flex-c mt-5">
                  <div className="fif">
                    <div className="col s12 m12">
                      <div className="form-bord">
                        <label>
                          <input
                            name=""
                            type="checkbox"
                            onClick={(e) => {
                              changetmpu({
                                ...tmpu,
                                contactbyphone: e.target.checked ? 1 : 0,
                              });
                              //setContactMethod(tmpu);
                            }}
                          />
                          <span>Puedes contactarme por teléfono</span>
                        </label>
                      </div>
                    </div>
                    <div className="col s12 m12">
                      <div className="form-bord">
                        <label>
                          <input
                            name=""
                            type="checkbox"
                            onClick={(e) => {
                              changetmpu({
                                ...tmpu,
                                contactbyemail: e.target.checked ? 1 : 0,
                              });
                              //setContactMethod(tmpu);
                            }}
                          />
                          <span>Puedes contactarme por mail</span>
                        </label>
                      </div>
                    </div>
                    <div className="col s12 m12">
                      <div className="form-bord">
                        <label>
                          <input
                            name=""
                            type="checkbox"
                            onClick={(e) => {
                              changetmpu({
                                ...tmpu,
                                contactbywhatsapp: e.target.checked ? 1 : 0,
                              });
                              //setContactMethod(tmpu);
                            }}
                          />
                          <span>Puedes contactarme por Whatsapp</span>
                        </label>
                      </div>
                    </div>
                    {/* FORMULARIO 2 */}
                    <div className="col s12 m12 center mt-3 mb-3">
                      <button
                        className={styleclass.bgPrimary + " cotiza"}
                        onClick={(e) => {
                          onSubmit(e);
                          e.target.disabled = true;
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CardContact changeWizard={changeWizard} contactMethod={contactMethod} setContactMethod={setContactMethod} vehicleR={data} concept={concept} styleclass={styleclass}></CardContact>
    </>
  );
};

export default FormDatosContacto;
