import React from "react";

export default function WelcomePremium() {
  return (
    <div id="modal-welcome-premium" className="modal">
      <div className="modal-content">
        <div className="flex-center-alw"></div>
        <a href="#" className="modal-close top-left">
          Cerrar
        </a>
        <h5 className="st text-center">Suscribete a click+ Premium</h5>
        <div className="modal-header">
          <img className="full-img" src="/images/back-diamond.jpg" alt="" />
        </div>
        <div className="row mid-pad">
          <h5 className="st text-center">Bienvenido a nuestro plan premium</h5>
          <p className="small-txt text-center">Comienza a generar prospectos y ten el control de tu cartera de productos Ahora tienes acceso a todas las herramientas de personalizacion.</p>
          <div className="mt-5 text-center ">
            <button className="waves-effect sq-btn darken-1 white-text modal-close">Comencemos</button>
          </div>
        </div>
      </div>
    </div>
  );
}
