import { URL_CONSULTA_LEADS } from "../../utils/constants";
import axios from "axios";
import { authFetch } from "../../utils/fetch";

export async function getAllLeadsApi(logout, options = { name: "", gerencia: "", tipovend: "", page: 1 }) {
  try {
    /* options is a default value */
    const url = URL_CONSULTA_LEADS;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Filters: {
          NombreCompleto: "*" + options.name + "*",
          Gerencia: "*" + options.gerencia + "*",
          TipoVend: "*" + options.tipovend + "*",
          PageRows: options.page + "|30",
        },
      }),
    };
    const result = await authFetch(url, params, logout);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getleadStatsApi(user, logout) {
  try {
    /* options is a default value */
    const url = URL_CONSULTA_LEADS;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Filters: {
          Clave: user,
        },
      }),
    };
    const result = await authFetch(url, params, logout);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
