import React, { useState, useEffect, useContext } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import db from "../../../firebase/firebaseConfig";
import { collection, addDoc, query, doc, onSnapshot, updateDoc } from "firebase/firestore";
import useAuth from "../../../hooks/useAuth";

const FormBlock2 = (props) => {
  const { user } = useAuth();
  //console.log(user);
  const { setStepCard, setDataFirebase } = useContext(ContextGlobal);

  // const localApi = localStorage.getItem("dataApi") ? JSON.parse(localStorage.getItem("dataApi")) : [];
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  //console.log("From local storage",localDataAgent);
  //const [agent_data, changeAgentData] = useState({}); //Obtiene todo los datos from Firebase
  const [agent_data_local, changeDataAgentLocal] = useState(localDataAgent); //Cambios locales
  const [agent_email, changeAgentMail] = useState("");
  const [agent_phones, changeAgentPhoneList] = useState([
    { agent_phone: "", agent_phone_type: "" }, //Primer campo teléfono
    //{ agent_phone: "5523382047", agent_phone_type: "oficina" },
  ]);

  useEffect(() => {
    //console.log("Entro al formblock2");
  });
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
      //console.log("Current data vc2: ", doc.data());
      const datatmp = doc.data();
      if (datatmp?.agent_email != undefined && datatmp?.agent_email != null) {
        changeAgentMail(datatmp?.agent_email);
        changeAgentPhoneList(datatmp?.agent_phones);
        setDataFirebase(doc.data()); //SET THE PARAMAS
      }
    });
  }, []);

  const phoneValidate = (numero) => {
    let nospace = numero.replace(/\s/g, "");
    let nonumbers = nospace.replace(/[^0-9]+/g, "");
    return nonumbers;
  };

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    const list = [...agent_phones];

    if (name == "agent_phone") {
      console.log("valor del solo telefono", value);
      value = phoneValidate(value);
    }

    list[index][name] = value; //actualizamos el dato
    changeAgentPhoneList(list);
  };

  const handleAddInput = () => {
    const list = [...agent_phones];
    list.push({ agent_phone: "", agent_phone_type: "" });
    changeAgentPhoneList(list);
  };

  const handleRemoveInput = (index) => {
    const list = [...agent_phones];
    list.splice(index, 1);
    changeAgentPhoneList(list);
  };

  const emailFormat = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const sendForm = (e) => {
    e.preventDefault();

    if (agent_email == "") {
      M.toast({ html: "Debes completar el campo correo electrónico" });

      return false;
    } else {
      let resultEmail = emailFormat(agent_email);
      if (resultEmail == null) {
        M.toast({ html: "Ingresa un correo electrónico válido" });
        return false;
      }
    }


    //Aqui solo vamos a actualizar añadir datos
    const userRef = doc(db, "cp_agents", localDataAgent.document);
    updateDoc(userRef, {
      //...agent_data,
      step: 3,
      agent_email: agent_email,
      agent_phones: agent_phones,
    })
      .then(() => {
        localStorage.setItem(
          "dataagent",
          JSON.stringify({
            ...agent_data_local,
            step: 3,
            custom: false,
            option: "No mostrar",
            iscomplete: false,
          })
        );
        setStepCard(3, false); //Setting in the main view
        props.stepFormChange(3);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const setDataFromApi = () => {
    if (user?.Telefono != undefined && user?.Telefono != null) {
      changeAgentMail(user.Email);
      let telefono = user.Telefono;
      telefono = telefono.replace("Celular|", "");
      changeAgentPhoneList([{ agent_phone: telefono, agent_phone_type: "Personal" }]);
    } else {
      changeAgentMail("");
      changeAgentPhoneList([{ agent_phone: "", agent_phone_type: "Personal" }]);
    }
  };

  return (
    <div className="col m12 s12">
      <h5 className="st text-center">Forma de contacto</h5>
      <form onSubmit={sendForm}>
        <input type="hidden" name="stepinput" value="3" readOnly />
        <div className="input-field col m12 s12 text-left">
          <div className="box-bl z-depth-3 mt-3">
            <label>
              <input type="radio" className="with-gap" onClick={setDataFromApi} />
              <span>Quiero utilizar mis datos registrados en click</span>
            </label>
          </div>
        </div>
        <div className="input-field col m12 s12 mt-5">
          <div className="flex-alw">
            <span className="material-icons circ-form i-white mr-3">mail</span>
            <input
              id="agent_email"
              type="text"
              className="validate"
              value={agent_email}
              onChange={(e) => {
                changeAgentMail(e.target.value);
              }}
            />
          </div>
          <label htmlFor="agent_email" className="active pnl-5">
            ¿Cuál es tu mail de contacto?
          </label>
        </div>

        {agent_phones.map((item, index) => {
          return (
            <div key={index}>
              <div className="input-field col m12 s12">
                <div className="flex-alw">
                  <span className="material-icons circ-form i-white mr-3">call</span>
                  <input id={"agent_phone" + index} 
                  type="text" 
                  className="validate" 
                  name="agent_phone"
                  maxLength={10}
                  value={item.agent_phone} onChange={(e) => handleChange(e, index)} />
                  {/* onChange={ (e) => { handleChange(e,i)}}  es igual a esto ¿? */}
                </div>
                <label htmlFor={"agent_phone" + index} className="active pnl-5">
                  ¿Cuál es tu teléfono?
                </label>
              </div>
              <div className="input-field col m12 s12">
                <div className="flex-alw">
                  <span className="material-icons circ-form i-white mr-3">phonelink_setup</span>
                  <input
                    id={"agent_phone_type" + index}
                    type="text"
                    className="validate"
                    name="agent_phone_type"
                    
                    value={item.agent_phone_type}
                    onChange={
                      (e) => {
                        handleChange(e, index)
                      }}
                  />
                </div>
                <label htmlFor={"agent_phone_type" + index} className="active pnl-5">
                  ¿Qué tipo de teléfono es?
                </label>
              </div>
              <div className="col m12 s12 text-center mdb-sp">
                {index > 0 ? (
                  <span className="pointer btn red mr-4" onClick={() => handleRemoveInput(index)}>
                    Quitar {index}
                  </span>
                ) : (
                  ""
                )}
                {agent_phones.length - 1 === index && (
                  <span className="pointer btn-dk z-depth-3" onClick={handleAddInput}>
                    Agregar otro teléfono
                  </span>
                )}
              </div>
            </div>
          );
        })}

        {/* {JSON.stringify(agent_phones, null, 2)} */}
        <div className="input-field col m12 s12 text-center">
          <button type="submit" className="waves-effect sq-btn darken-1 white-text" id="buttonBlock2">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormBlock2;
