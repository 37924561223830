import React, { useContext } from "react";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const FormAgentCard = ({ changeCotizar, changeFSC, changeForms, style, styleclass, dataAgnFb }) => {
  const { changeWizard } = useContext(ContextGlobal);
  const endForm = (e) => {
    window.location.href= "/";
    e.preventDefault();
    changeForms(1);
    changeCotizar(""); //Clean all
    changeWizard(false);
    changeFSC({
      formStep: 1,
    });
    console.log("Finalizando los formularios...");
  };

  return (
    <div className={`row ${style}`}>
      <div className="col s12 m12">
        <div className="card radius-card pad-card">
          <div className="row">
            <div className="col s12 m10">
              <h3 className="st">Pronto me pondré en contacto contigo</h3>
              <p>Estos son mis datos, por favor agrégame a tu agenda</p>
            </div>
          </div>

          <form
            onSubmit={(e) => {
              endForm(e);
            }}
          >
            <div className="row mt-5">
              <div className="col s12 m6 pad-right">
                <div className="txt-label">Teléfono principal</div>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">phone</i>
                  <span>{dataAgnFb.agent_phones !== undefined && dataAgnFb.agent_phones !== null ? dataAgnFb.agent_phones[0].agent_phone : "No registrado"}</span>
                </div>
                <div className="clr"></div>
                <div className="txt-label">WhatsApp</div>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">chat</i>
                  <span>
                    <span>{dataAgnFb.agent_phones !== undefined && dataAgnFb.agent_phones !== null ? dataAgnFb.agent_phones[0].agent_phone : "No registrado"}</span>
                  </span>
                </div>
                <div className="clr"></div>
                <div className="txt-label">Mail</div>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">email</i>
                  <span>{dataAgnFb.agent_email !== undefined && dataAgnFb.agent_email !== null ? dataAgnFb.agent_email : "No registrado"}</span>
                </div>
                <div className="clr"></div>
                {/* <button className="sc-btn mt-5 full">Guardar en tu agenda</button> */}
              </div>
              <div className="col s12 m6 ">
                <img src={styleclass.conimage === undefined ? "/images/man.jpg" : `${styleclass.conimage}`} alt="profile-picture" className="responsive-img" />
              </div>

              <div className="col s12 m12 mt-3 mb-3">
                <button className={styleclass.bgPrimary + " cotiza"} type="submit">
                  Terminar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FormAgentCard;
