import React from "react";

const ShowCardAgent = (props) => {
  const { stateCard, dataAgnFb } = props;

  return (
    <div id="modalTarjeta" className="modal" style={{ width: "60%" }}>
      {stateCard.iscomplete === true ? (
        <div className="row">
          <div className="col m12 s12 pt-5 pb-5 pl-3 pr-3">
            {dataAgnFb?.agent_url !== "undefined" && dataAgnFb?.agent_url !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">language</i>
                  <span>{dataAgnFb?.agent_url}</span>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.agent_name !== "undefined" && dataAgnFb?.agent_name !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">account_circle</i>
                  <span>{dataAgnFb?.agent_name}</span>
                </div>
              </>
            ) : (
              ""
            )}

            {dataAgnFb?.agent_email !== "undefined" && dataAgnFb?.agent_email !== null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">mail_outline</i>
                  <span>{dataAgnFb?.agent_email}</span>
                </div>
              </>
            ) : (
              ""
            )}

            {dataAgnFb?.agent_phones != undefined && dataAgnFb?.agent_phones != null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">phone_android</i>
                  <span>{dataAgnFb?.agent_phones[0].agent_phone}</span>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.agent_schedule != "undefined" && dataAgnFb?.agent_phones != null ? (
              <>
                <div className="col m12 s12 valign-wrapper wht">
                  <i className="material-icons prefix ico-grey">date_range</i>
                  <span>{dataAgnFb?.agent_schedule.horario}</span>
                </div>
              </>
            ) : (
              ""
            )}
            {dataAgnFb?.redes_agente != "undefined" && dataAgnFb?.agent_phones != null ? (
              <>
                {dataAgnFb?.redes_agente.length > 0 ? (
                  <>
                    {dataAgnFb?.redes_agente.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="col m12 s12 valign-wrapper wht">
                            <div className="red-ico ico-grey">
                              <img src={item.icono} alt="" />
                            </div>
                            {item.agent_url_social}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="row mb-0">
          <div className="col m12 s12 text-right pt-3">
            <span className="modal-close pointer">
              <i className="material-icons">close</i>
            </span>
          </div>
          <div className="col m12 s12 pt-1 pb-5 pl-3 pr-3 text-center">
            <h4 className="mb-0">Debes completar tu tarjeta de contacto para visualizar la información.</h4>
          </div>
        </div>
      )}
    </div>
  );
};
export default ShowCardAgent;
