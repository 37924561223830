import React, { useState, useEffect, useContext } from "react";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import db from "../../../firebase/firebaseConfig";
//import { collection, addDoc, query, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
const FormBlock3 = (props) => {
  const { setStepCard } = useContext(ContextGlobal);

  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  //const [agent_data, changeAgentData] = useState({}); //Obtiene todo los datos from Firebase
  //const [agent_data_local, changeDataAgentLocal] = useState(localDataAgent); //Cambios locales
  const [agent_schedule, changeAgenSch] = useState({
    horario: "",
    custom: false,
    schedule: [{}],
  });

  const [customHor, changeCustomHor] = useState([
    { dia: "Lunes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Martes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Miércoles", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Jueves", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Viernes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Sábado", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Domingo", apertura: "No disponible", cierre: "No disponible" },
  ]);
  const [valueChecked, changeValueChecked] = useState(localDataAgent.option);
  const [selectHor, chSelHor] = useState(localDataAgent.custom); //Show hide element selects

  const defaultHor = [
    { dia: "Lunes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Martes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Miércoles", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Jueves", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Viernes", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Sábado", apertura: "No disponible", cierre: "No disponible" },
    { dia: "Domingo", apertura: "No disponible", cierre: "No disponible" },
  ];

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
      const datatmp = doc.data();
      if (datatmp.agent_schedule != undefined && datatmp.agent_schedule != null) {
        changeAgenSch(datatmp?.agent_schedule);
        //Validar los datos antes del setup
        const hasDataHorario = datatmp.agent_schedule.schedule;
        const respHorario = hasDataHorario.length <= 1 ? customHor : hasDataHorario;
        changeCustomHor(respHorario);

        const isCustom = datatmp.agent_schedule.custom;
        chSelHor(isCustom);
        changeValueChecked(datatmp.agent_schedule.horario);
      } else {
      }
    });
    return () => {
      unsub();
    };
  }, []);

  const FillSelect = (props) => {
    return (
      <>
        <option value="No disponible">No disponible</option>
        <option value="00:00">00:00</option>
        <option value="00:30">00:30</option>
        <option value="01:00">01:00</option>
        <option value="01:30">01:30</option>
        <option value="02:00">02:00</option>
        <option value="02:30">02:30</option>
        <option value="03:00">03:00</option>
        <option value="03:30">03:30</option>
        <option value="04:00">04:00</option>
        <option value="04:30">04:30</option>
        <option value="05:00">05:00</option>
        <option value="05:30">05:30</option>
        <option value="06:00">06:00</option>
        <option value="06:30">06:30</option>
        <option value="07:00">07:00</option>
        <option value="07:30">07:30</option>
        <option value="08:00">08:00</option>
        <option value="08:30">08:30</option>
        <option value="09:00">09:00</option>
        <option value="09:30">09:30</option>
        <option value="10:00">10:00</option>
        <option value="10:30">10:30</option>
        <option value="11:00">11:00</option>
        <option value="11:30">11:30</option>
        <option value="12:00">12:00</option>
        <option value="12:30">12:30</option>
        <option value="13:00">13:00</option>
        <option value="13:30">13:30</option>
        <option value="14:00">14:00</option>
        <option value="14:30">14:30</option>
        <option value="15:00">15:00</option>
        <option value="15:30">15:30</option>
        <option value="16:00">16:00</option>
        <option value="16:30">16:30</option>
        <option value="17:00">17:00</option>
        <option value="17:30">17:30</option>
        <option value="18:00">18:00</option>
        <option value="18:30">18:30</option>
        <option value="19:00">19:00</option>
        <option value="19:30">19:30</option>
        <option value="20:00">20:00</option>
        <option value="20:30">20:30</option>
        <option value="21:00">21:00</option>
        <option value="21:30">21:30</option>
        <option value="22:00">22:00</option>
        <option value="22:30">22:30</option>
        <option value="23:00">23:00</option>
        <option value="23:30">23:30</option>
      </>
    );
  };

  const setSchedule = (e, hasoptions) => {
    if (!hasoptions) {
      chSelHor(hasoptions);
      changeAgenSch({
        horario: e.target.value,
        custom: hasoptions,
        schedule: defaultHor, // nunca cambia los valores
      });
    } else {
      chSelHor(hasoptions);
      const list = {
        ...agent_schedule,
        horario: e.target.value,
        custom: hasoptions,
        schedule: customHor,
      };
      changeAgenSch(list);
    }
    changeValueChecked(e.target.value);
  };

  const updateItem = (e, index) => {
    const { name, value } = e.target;
    const list = [...customHor];
    list[index][name] = value; //actualizamos el dato
    changeCustomHor(list);
  };

  const sendForm = (e) => {
    e.preventDefault();
    //merge the objects
    //validate if the option was selected
    if (selectHor) {
      //last update
      const list = { ...agent_schedule, schedule: customHor };
      changeAgenSch(list);
    } else {
      const list = { ...agent_schedule, schedule: defaultHor };
      changeAgenSch(list);
    }

    //Aqui solo vamos a actualizar añadir datos
    const userRef = doc(db, "cp_agents", localDataAgent.document);
    updateDoc(userRef, {
      //...agent_data,
      step: 4,
      agent_schedule,
    })
      .then(() => {
        //Actualizamos la información
        localStorage.setItem(
          "dataagent",
          JSON.stringify({
            ...localDataAgent,
            custom: selectHor,
            option: valueChecked,
            step: 4,
            iscomplete: false,
          })
        );
        setStepCard(4, false); //Setting in the main view
        props.stepFormChange(4);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error(error);
      });

    //props.stepFormChange(4);
  };
  return (
    <div className="col m12 s12">
      <h5 className="st text-center">Horario comercial</h5>
      <p className="dt-sp">¿Qué horario de atención a tus clientes tienes?</p>
      <form onSubmit={sendForm}>
        <input type="hidden" name="stepinput" value="4" readOnly />
        <div className="input-field col m12 s12 text-start">
          <p className="rec-dkf z-depth-3">
            <label>
              <input
                type="radio"
                name="horario"
                className="with-gap cyan"
                value="No mostrar"
                defaultChecked={valueChecked === "No mostrar" ? true : false}
                onClick={(e) => {
                  setSchedule(e, false);
                }}
              />
              <span>No mostrar mi horario</span>
            </label>
          </p>
          <p className="rec-dkf z-depth-3">
            <label>
              <input
                type="radio"
                name="horario"
                className="with-gap"
                value="Siempre disponible"
                defaultChecked={valueChecked === "Siempre disponible" ? true : false}
                onClick={(e) => {
                  setSchedule(e, false);
                }}
              />
              <span>Siempre disponible</span>
            </label>
          </p>
          <p className="rec-dkf z-depth-3">
            <label>
              <input
                type="radio"
                name="horario"
                className="with-gap"
                value="Disponible en horas concretas"
                defaultChecked={valueChecked === "Disponible en horas concretas" ? true : false}
                onClick={(e) => {
                  setSchedule(e, true);
                }}
              />
              <span>Disponible en horas concretas</span>
            </label>
          </p>
        </div>

        {selectHor ? (
          <div className="input-field col m12 s12 text-start">
            {customHor.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col m2 s12">
                    <p>{item.dia}</p>
                  </div>
                  <div className="col m5 s6">
                    <label>Apertura</label>
                    <select
                      className="browser-default"
                      value={item.apertura}
                      name="apertura"
                      onChange={(e) => {
                        updateItem(e, index);
                      }}
                      required
                    >
                      <FillSelect value={item.apertura} />
                    </select>
                  </div>
                  <div className="col m5 s6">
                    <label>Cierre</label>
                    <select
                      className="browser-default"
                      value={item.cierre}
                      name="cierre"
                      onChange={(e) => {
                        updateItem(e, index);
                      }}
                      required
                    >
                      <FillSelect value={item.cierre} />
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className="input-field col m12 s12 text-center">
          <button type="submit" className="waves-effect sq-btn darken-1 white-text" id="buttonBlock4">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormBlock3;
