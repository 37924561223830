import React from 'react';
import FormBudget from '../agentweb/FormBudget';
const ModalCotizar = ({changeCotizar, styleclass, dataAgnFb, subdomain, firebaseUser, publicToken }) => {
    return (
        <div id="modalCotizar" className="modal" style={ {width: "96%"} }>
            <div className="modal-content p-0" >
                <div className="row">
                    <FormBudget
                        flagCotizar={"showForm"}
                        changeCotizar={changeCotizar}
                        styleclass={styleclass}
                        dataAgnFb={dataAgnFb}
                        subdomain={subdomain}
                        firebaseUser={firebaseUser}
                        publicToken={publicToken}
                    ></FormBudget>
                </div>
            </div>
        </div>
    );
}
export default ModalCotizar;