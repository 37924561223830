import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";
import { useParams, useRouteMatch } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const Solicitudes = () => {
  const { user } = useAuth();
  const url_step_1 = "https://ws.developmentservices.com.mx/Api3.0/ApiLoginRest/api/login/authenticate/";
  const urlGetLeads = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";

  //Desde el contexto validamos
  const { idleadCTX, changeLeadCTX } = useContext(ContextGlobal);

  //Son los leads de la cookie, pero la sincronización no es la adecuada. Local vs Server
  const notifications = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : [];
  const tokenReq = localStorage.getItem("tokenReq") ? JSON.parse(localStorage.getItem("tokenReq")) : [];
  const usrClick = user ? user : [];
  const [usrVisible, changeVisible] = useState(1);
  const [leadFeed, changeFeed] = useState(0); //Todos
  const [leadRamo, changeRamo] = useState(0); //Todos

  const [idContacto, chIdCont] = useState(0);
  const [statusCont, chStatusCont] = useState(1);

  const modals = document.querySelectorAll(".modal");

  const [pageSelected, changePage] = useState(1);
  const [limitCtr, changeLimit] = useState(20);
  let { path, url } = useRouteMatch();
  const [showColumn, changecolumn] = useState(false);
  const [leads, changeLeads] = useState([]);
  const [lead, changeData] = useState({
    name: "",
    email: "",
    phone: "",
    reason: "",
    register: "",
    modelo: "",
    marca: "",
    txtramo: "",
    detalles: "",
    idLead: 0,
    whatsapp: false,
    emailcontact: false,
    telefono: false,
    leadstatus: 0,
  });
  const setDataCard = (flag, lead) => {
    console.log(" ::: Lead desde el contexto ::: ", idleadCTX);
    if (flag === true) changecolumn(flag);
    //changecolumn(flag);
    changeData({
      idLead: lead.IDCont,
      name: lead.Nombre, //+ " " + lead.ApellidoP + " " + lead.ApellidoM,
      email: lead.Email,
      phone: lead.Telefono,
      register: lead.FechaCap,
      reason: lead.Detalle, //Aqui viene el del hogar y seguro medico, //Se cambia a campo detalles
      modelo: lead.Modelo,
      marca: lead.Marca,
      txtramo: lead.Txt_SRamo,
      detalles: lead.Detalle,
      whatsapp: lead.ContWhats,
      emailcontact: lead.ContEmail,
      telefono: lead.ContTel,
      leadstatus: lead.Status,
    });

    if (lead.Leido === false) {
      updateLead(lead.IDCont, lead.Status, lead.Visible);
    }
  };

  const loadLeads = (pageSelected = 1, visible = 1, leadFeed = 0, leadFeedTipo = 0) => {
    axios
      .post(url_step_1, { Token: "k2q08aN+zAmcH5/dPMELrQ==" })
      .then(function (response) {
        //console.log("LoadSolicitudes con Notificaciones",response);
        localStorage.setItem("tokenReq", JSON.stringify(response.data));
        fetchData(response.data, pageSelected, visible, leadFeed, leadFeedTipo);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchData = (dataToken, page = 1, visible, leadFeedPar, leadFeedTipo) => {
    const options = { Authorization: "Click " + dataToken.Token };
    //TODS
    let params = {
      Token: "k2q08aN+zAmcH5/dPMELrQ==",
      TypeProcess: "5",
      Filters: {
        IDPortal: "ABCDEF0123",
        //IDVend: 3,
        Visible: 1,
        IDVend: usrClick.IdVend,
        PageRows: page + "|" + limitCtr,
      },
    };

    if (leadFeedPar != 0 && leadFeedTipo == 0) {
      params = {
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "5",
        Filters: {
          IDPortal: "ABCDEF0123",
          //IDVend: 3,
          Visible: 1,
          IDVend: usrClick.IdVend,
          Status: leadFeedPar,
          PageRows: page + "|" + limitCtr,
        },
      };
    }
    if (leadFeedPar != 0 && leadFeedTipo != 0) {
      params = {
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "5",
        Filters: {
          IDPortal: "ABCDEF0123",
          //IDVend: 3,
          Visible: 1,
          IDVend: usrClick.IdVend,
          Status: leadFeedPar,
          IDSRamo: leadFeedTipo,
          PageRows: page + "|" + limitCtr,
        },
      };
    }

    if (leadFeedPar == 0 && leadFeedTipo != 0) {
      params = {
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "5",
        Filters: {
          IDPortal: "ABCDEF0123",
          //IDVend: 3,
          Visible: 1,
          IDVend: usrClick.IdVend,
          Status: leadFeedPar,
          IDSRamo: leadFeedTipo,
          PageRows: page + "|" + limitCtr,
        },
      };
    }

    //Condicion de status
    //Busqueda por IDSRamo no funciona :/
    axios
      .post(urlGetLeads, params, { headers: options })
      .then(function (response) {
        //console.log("Solicitudes => ", response.data);
        let result = response.data;
        localStorage.setItem("notifications", JSON.stringify(result.DataResponse));
        if (result.DataResponse == null) {
          console.log("Sin resultados => ...");
          changeLeads([]);
        } else {
          console.log("Se realizo la consulta => ...");
          console.log(result.DataResponse);
          changeLeads(result.DataResponse);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterLeadFeed = (e) => {
    changeFeed(e.target.value);
    loadLeads(pageSelected, usrVisible, e.target.value, leadRamo);
  };

  const filterLeadFeedTipo = (e) => {
    changeRamo(e.target.value);
    loadLeads(pageSelected, usrVisible, leadFeed, e.target.value);
    //IDSRamo //Is the param
  };

  const showLeadsPaginator = (page) => {
    let result = pageSelected + page;
    let newPage = result == 0 ? 1 : result;
    console.log("valor de la opción", newPage);
    loadLeads(newPage, usrVisible);
    changePage(pageSelected + page);
  };

  //const onChangeLead = (RowId, statusLead) => {
  const onChangeLead = (e, RowId) => {
    /* cuando usamos el select */
    //console.log("Valor del Select => ", e.target.value);
    //console.log("Valor del registro => ", RowId);
    chStatusCont(e.target.value);
    updateLead(RowId, e.target.value, 1);
  };

  const deleteRow = (e, RowId, statusLead, visible) => {
    e.preventDefault();
    let alerta = window.confirm("¿Deseas eliminar este registro?\n Está acción ya no se puede deshacer.");
    if (alerta) {
      updateLead(RowId, statusLead, visible);
    }
  };

  const updateLead = (RowId, statusLead, visible) => {
    let params = {
      Token: "k2q08aN+zAmcH5/dPMELrQ==",
      TypeProcess: "3",
      Params: {
        IDCont: RowId, //Row ID
        Status: statusLead, //Status in change Select
        Leido: true, //When the user maked clic , ever activates
        Visible: visible, //When the user "delete" some record
      },
    };
    const urlUpdate = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";
    const options = { Authorization: "Click " + tokenReq.Token };
    axios
      .post(urlUpdate, params, { headers: options })
      .then(function (response) {
        let result = response.data;
        if (result.Success) {
          console.log("Se actualizo el lead : ", RowId);
          loadLeads(pageSelected);
        } else {
          console.log("No se pudo actualizar el lead : ", RowId);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const labelServicio = (jsonStr) => {
    if (jsonStr == null || jsonStr == "") {
      //console.log("Esta vacía la cadena : ",jsonStr)
    } else {
      const jsonServ = JSON.parse(jsonStr);
      if (jsonServ === null || jsonServ == "") {
        return (
          <>
            <span>No registrado</span>
          </>
        );
      } else {
        return (
          <>
            <span>{jsonServ.concept}</span>
          </>
        );
      }
    }
  };

  const labelIcon = (jsonStr) => {
    let icon_current = "directions_car";
    if (jsonStr == null || jsonStr == "") {
    } else {
      const jsonServ = JSON.parse(jsonStr);
      if (jsonServ === null || jsonServ == "") {
        return (
          <>
            <span>No registrado</span>
          </>
        );
      } else {
        //Seguro Hogar
        //Seguro Médico
        //Seguro Vehículo
        //This must will be change according with the vehicle

        if (jsonServ.concept === "Seguro Hogar") {
          icon_current = "home";
        }
        if (jsonServ.concept === "Seguro Médico") {
          icon_current = "local_hospital";
        }
      }
    }
    return icon_current;
  };

  const labelDate = (fecha) => {
    let str = fecha.split("T");
    return (
      <>
        Fecha: {str[0]}
        <span className="time-list">{str[1]} Hrs</span>
      </>
    );
  };

  const showRequest = (jsonStr) => {
    if (jsonStr == null || jsonStr == "") {
    } else {
      const jsonServ = JSON.parse(jsonStr);
      if (jsonServ === null || jsonServ == "") {
        return (
          <>
            <span>No registrado</span>
          </>
        );
      } else {
        //Seguro Hogar
        //Seguro Médico
        //Seguro Vehículo //This must will be change according with the vehicle

        if (jsonServ.concept === "Seguro Vehículo") {
          console.log("vehiculos =>", jsonServ.data);
          return (
            <div className="col m12 s12">
              <div className="col m4 s12">
                <label htmlFor="">Modelo</label>
                <p className="mb-0">{jsonServ.data.modelo}</p>
              </div>
              <div className="col m4 s12">
                <label htmlFor="">Marca</label>
                <p className="mb-0">{jsonServ.data.marca}</p>
              </div>
              <div className="col m4 s12">
                <label htmlFor="">Versión</label>
                <p className="mb-0">{jsonServ.data.version}</p>
              </div>
              <div className="col m6 s12">
                <label htmlFor="">Tipo de unidad</label>
                <p className="mb-0">{jsonServ.data.unidad}</p>
              </div>
            </div>
          );
        }

        if (jsonServ.concept === "Seguro Hogar") {
          console.log("hogar =>", jsonServ.data);
          return (
            <div className="col m12 s12">
              <div className="row">
                <div className="col m6 s12 mb-3">
                  <label htmlFor="">Acerca de la propiedad</label>
                  <p className="mb-0">{jsonServ.data.acercahogar}</p>
                </div>
                <div className="col m6 s12 mb-3">
                  <label htmlFor="">Tipo de construcción</label>
                  <p className="mb-0">{jsonServ.data.tipoconstruccion == "" ? "No registrado" : jsonServ.data.tipoconstruccion}</p>
                </div>
                <div className="col m6 s12 mb-3">
                  <label htmlFor="">Valor de la propiedad</label>
                  <p className="mb-0">{jsonServ.data.valorpropiedad == "" ? "No registrado" : jsonServ.data.valorpropiedad}</p>
                </div>
                <div className="col m6 s12 mb-3">
                  <label htmlFor="">Valor de las pertenencias</label>
                  <p className="mb-0">{jsonServ.data.valorpertenencias == "" ? "No registrado" : jsonServ.data.valorpertenencias}</p>
                </div>
              </div>
              <div className="row">
                <div className="col m4 s12">
                  <label htmlFor="">No. de pisos</label>
                  <p className="mb-0">{jsonServ.data.numpisos}</p>
                </div>
                <div className="col m4 s12">
                  <label htmlFor="">No. de sótanos</label>
                  <p className="mb-0">{jsonServ.data.numsotanos}</p>
                </div>
                <div className="col m4 s12">
                  <label htmlFor="">Código postal</label>
                  <p className="mb-0">#{jsonServ.data.codigopostal}</p>
                </div>
              </div>
            </div>
          );
        }

        if (jsonServ.concept === "Seguro Médico") {
          console.log("medico =>", jsonServ.data);
          let people = jsonServ.data;
          return (
            <div className="col m12 s12">
              <p className="mb-3">Datos de los asegurados:</p>
              {people.map((subitem, subindex) => {
                if (subitem.show)
                  return (
                    <div className="row" key={subindex}>
                      <div className="col m6 s12">
                        <label htmlFor="">Edad: </label>
                        <p className="mb-0">{subitem.edad}</p>
                      </div>
                      <div className="col m6 s12">
                        <label htmlFor="">Genero: </label>
                        <p className="mb-0 text-uppercase">{subitem.genero}</p>
                      </div>
                    </div>
                  );
              })}
            </div>
          );
        }
      }
    }
  };

  const editLead = (e) => {
    //console.log(e.target.name);
    //let value = e.target.value;
    switch (e.target.name) {
      case "name":
        changeData({
          ...lead,
          name: e.target.value,
        });
        break;

      case "email":
        changeData({
          ...lead,
          email: e.target.value,
        });
        break;

      case "phone":
        changeData({
          ...lead,
          phone: e.target.value,
        });
        break;
    }
  };
  const modalUpateStatus = (event, status, idContacto) => {
    /*Change global params*/
    chIdCont(idContacto);
    chStatusCont(status);
  };

  const updateAndSend = (usrlead) => {
    let params = {
      Token: "k2q08aN+zAmcH5/dPMELrQ==",
      TypeProcess: "3",
      Params: {
        IDCont: usrlead.idLead,
        Nombre: usrlead.name,
        Email: usrlead.email,
        Telefono: usrlead.phone,
      },
    };

    const form1 = document.getElementById("form-lead-show");
    const form2 = document.getElementById("form-lead-hide");
    const form3 = document.getElementById("form-lead-text");
    const form4 = document.getElementById("form-lead-close");

    form1.style.display = "none";
    form2.style.display = "block";

    const urlUpdate = "https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leads";
    const options = { Authorization: "Click " + tokenReq.Token };
    axios
      .post(urlUpdate, params, {
        headers: options,
      })
      .then(function (response) {
        let result = response.data;
        if (result.Success) {
          //console.log("Se actualizo el lead : ", usrlead.idLead);
          loadLeads(pageSelected);
          let subparams = {
            Token: "k2q08aN+zAmcH5/dPMELrQ==",
            Params: {
              IDCont: usrlead.idLead,
              //"Cve_Vend": usrClick.IdVend,
              //"Cve_Vend": usrClick.IdCont,
              Cve_Vend: usrClick.Clave,
            },
          };
          axios
            .post("https://ws.developmentservices.com.mx/Api3.0/ApiClickPlus/api/clickplus/leadtocli", subparams, {
              headers: options,
            })
            .then(function (subresp) {
              let sub = subresp.data;
              console.log("Resultado lead", sub);
              //M.toast({ html: "" });
              form3.innerHTML = "Se ha registrado el contacto en tu agenda.";
              form4.style.display = "block";
            })
            .catch(function (error) {
              M.toast({ html: error });
            });
        } else {
          console.log("No se pudo actualizar el lead : ", usrlead.idLead);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const returnNormal = () => {
    changeLeadCTX(null);
    const form1 = document.getElementById("form-lead-show");
    const form2 = document.getElementById("form-lead-hide");
    const form3 = document.getElementById("form-lead-text");
    const form4 = document.getElementById("form-lead-close");
    form1.style.display = "block";
    form2.style.display = "none";
    form3.innerHTML = "Espere un momento por favor...";
    form4.style.display = "none";
  };

  const searchByName = (value) => {
    const options = { Authorization: "Click " + tokenReq.Token };
    let params = {
      Token: "k2q08aN+zAmcH5/dPMELrQ==",
      TypeProcess: "5",
      Filters: {
        IDPortal: "ABCDEF0123",
        //IDVend: 3,
        visible: 1,
        IDVend: usrClick.IdVend,
        Nombre: "*" + value + "*",
        PageRows: "1|200",
      },
    };

    axios
      .post(urlGetLeads, params, {
        headers: options,
      })
      .then(function (response) {
        let result = response.data;

        if (result.DataResponse == null) {
          changeLeads([]);
        } else {
          console.log("Se realizo la consulta => ...");
          changeLeads(result.DataResponse);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteLead = (id) => {
    console.log("Eliminar lead => ", id);
  };

  useEffect(() => {
    M.AutoInit(); //If the view is new use this always
    M.Modal.init(modals, {
      dismissible: false,
    });
    loadLeads(pageSelected);
    //changeLeads(notifications);
  }, []);

  useEffect(() => {
    //console.log("Url web site", path);
    console.log("usuario visible", usrVisible);
  }, [usrVisible]);

  useEffect(() => {
    console.log("El valor del lead referenciado", idleadCTX);
    if (idleadCTX !== null) {
      setDataCard(true, idleadCTX);
    } else {
      changecolumn(false);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col m12 s12 flex-start-center mt-4 mb-2">
          <NavLink to="/">
            <i className="ico-ftl ico-circ wh icon-facebook">&#xf104;</i>
          </NavLink>
          <h4 className="ml-2 st no-mar">Solicitudes de cotización</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col m12 s12">
          <div className="row">
            <div className={showColumn ? "col m7 s12" : "col m12 s12"}>
              <div className="card">
                <div className="card-content">
                  <div className="">
                    <div className="box-pad pb-1">
                      <div className="row">
                        <div className="col m6 s12">
                          <div className="search-inpt flex-start-center">
                            <span className="material-icons i-search">search</span>
                            <input
                              placeholder="Busca un nombre"
                              id=""
                              type="text"
                              className="browser-default"
                              onChange={(e) => {
                                searchByName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col m6 s12 text-right">
                          <NavLink to="/solicitudespapelera" className="chip red white-text">
                            <span className="inline-icon material-icons">delete</span> Ir a la papelera
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="top-border mb-4">
                      <ul className="rqst">
                        <li>
                          {/* <div className="flex-start-center">
                                                        <a className="grl-btn" href="#" onClick={() => { changeVisible(usrVisible === 0 ? 1 : 0); loadLeads(pageSelected, (usrVisible === 0 ? 1 : 0)) }}>
                                                            <span className="material-icons ico-sm">{(usrVisible === 0) ? "delete" : "tune"}</span>
                                                            <span>{(usrVisible === 0) ? "Ver papelera" : "Ver todos"}</span>
                                                        </a>
                                                    </div> */}
                        </li>
                        <li>
                          <div className="">
                            <label>Estatus de contacto</label>
                            <select
                              className="browser-default"
                              onChange={(e) => {
                                filterLeadFeed(e);
                              }}
                            >
                              <option value="0">Todos</option>
                              <option value="1">Por contactar</option>
                              <option value="2">En seguimiento</option>
                              <option value="3">Cerrada ganada</option>
                              <option value="4">Cerrada perdida</option>
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="">
                            <label>Tipos de seguro</label>
                            <select
                              className="browser-default"
                              defaultValue="0"
                              onChange={(e) => {
                                filterLeadFeedTipo(e);
                              }}
                            >
                              <option value="0">Todos</option>
                              <option value="1">Autos Individual</option>
                              <option value="2">Motocicletas</option>
                              <option value="3">Safetrans</option>
                              <option value="4">Gastos Médicos</option>
                              <option value="5">Hogar</option>
                            </select>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="l-b">
                      {leads !== null ? (
                        <>
                          {leads.length === 0 ? (
                            <>
                              <h4 className="text-center pt-4 pb-4">Sin resultados</h4>
                            </>
                          ) : (
                            <>
                              {leads.map((item, index) => {
                                return (
                                  <div className="over-tr" key={index}>
                                    <div className="flex-btw ">
                                      <div
                                        className="st flex-start-center pointer serv-click"
                                        onClick={() => {
                                          setDataCard(!showColumn, item);
                                        }}
                                      >
                                        <div className="ico-list two-pad">
                                          <div className="ico-sol">
                                            {item.Leido === true ? <div className="rd"></div> : <div className="unrd"></div>}
                                            {/* click on click trigger change status */}
                                            <span className="material-icons circ-dark i-white">{labelIcon(item.Detalle)}</span>
                                          </div>
                                        </div>
                                        <div className="ml-3">
                                          {item.Nombre}
                                          <div className="txt-sm no-pad">
                                            {/* labelServicio(item.Detalle) */}
                                            Seguro {item.Txt_SRamo}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-start-center btnr">
                                        <div
                                          className="pointer txt-lit st text-center modal-trigger darken-1 white-text item-stats"
                                          data-target="modalStatus"
                                          onClick={(e) => {
                                            modalUpateStatus(e, item.Status, item.IDCont);
                                          }}
                                        >
                                          {item.Txt_Status}
                                        </div>
                                        <div className="btns-over">
                                          <div className="hdn-btns">
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                deleteRow(e, item.IDCont, item.Status, 0);
                                                console.log("borrar");
                                              }}
                                              className={"pointer"}
                                            >
                                              <span className="material-icons circ-blue i-blue z-depth-2">delete</span>
                                            </a>
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setDataCard(false, item);
                                                const showcard = document.getElementById("modalSave");
                                                const modal = M.Modal.init(showcard, {});
                                                modal.open();
                                              }}
                                            >
                                              <span className="material-icons circ-blue i-blue z-depth-2">person_add</span>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="dats">
                                          <div className="txt-lit  st">{labelDate(item.FechaCap)}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <h4>Cargando...</h4>
                        </>
                      )}
                    </div>
                    {leads !== null ? (
                      <>
                        {leads.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col m12 s12 text-center mt-3">
                                <button
                                  className="btn orange btn-sm ml-2 mr-2"
                                  disabled={pageSelected === 1 ? true : false}
                                  onClick={() => {
                                    showLeadsPaginator(-1);
                                  }}
                                >
                                  Anterior
                                </button>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {" "}
                                  Página {pageSelected}{" "}
                                </span>
                                <button
                                  className="btn orange btn-sm ml-2 mr-2"
                                  onClick={() => {
                                    showLeadsPaginator(+1);
                                  }}
                                >
                                  Siguiente
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Setup the condition prospect */}
            {/* Get the information was storage */}
            {/* <Route path={`${path}/:usrid`}> */}
            <div className={showColumn ? "col m5 s12" : "hidden"}>
              <div className="card l-b">
                <div className="row mb-0">
                  <div className="col m12 s12 text-right right mt-3 mr-3 pt-1" style={{ textAlign: "right" }}>
                    <a data-target="modalDelete" className="modal-trigger pl-2 pointer">
                      <span className="material-icons circ-blue i-blue z-depth-2">delete</span>
                    </a>
                    <a
                      className="hover-blue pointer pl-2"
                      onClick={(e) => {
                        e.preventDefault();
                        changecolumn(!showColumn);
                      }}
                    >
                      {/* <i className="material-icons">close</i> */}
                      <span className="material-icons circ-blue i-blue z-depth-2">close</span>
                    </a>
                  </div>
                </div>
                <div className="card-content l-b pt-0">
                  <div className="row">
                    <div className="col m12 s12 mt-3 mb-3 hidden">
                      <p>Tu contacto se ha guardado en tu agenda </p>
                    </div>
                    <h5 className="st mb-2">
                      Solicitud de cotización para {lead.txtramo}
                      {/* labelServicio(lead.detalles) */}
                    </h5>
                    <div className="mb-2 txt-lit">Información del solicitante</div>
                    <div className="col m12 s12 valign-wrapper wht">
                      <i className="material-icons prefix ico-grey">person</i>
                      <span>{lead.name}</span>
                    </div>
                    <div className="col m12 s12 valign-wrapper wht">
                      <i className="material-icons prefix ico-grey">email</i>
                      <span>{lead.email}</span>
                    </div>
                    <div className="col m12 s12 valign-wrapper wht">
                      <i className="material-icons prefix ico-grey">phone</i>
                      <span>{lead.phone}</span>
                    </div>
                    <div className="col m12 s12 wht p-3">
                      <p>Prefiere ser contactado por:</p>
                      <ul className="">
                        <li>
                          <i className="tiny material-icons pt-1">{lead.telefono ? "check" : "close"}</i>
                          <span>Teléfono</span>
                        </li>
                        <li>
                          <i className="tiny material-icons pt-1">{lead.emailcontact ? "check" : "close"}</i>
                          <span>Correo</span>
                        </li>
                        <li>
                          <i className="tiny material-icons pt-1">{lead.whatsapp ? "check" : "close"}</i>
                          <span>Whatsapp</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col m12 s12">
                      <h5>Información de la solicitud</h5>
                      {showRequest(lead.detalles)}
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <a href={"#"} id="modalSaveContact" data-target="modalSave" className="modal-trigger light-blue-btnb z-depth-1">
                      <div className="flex-center-alw ">
                        <div className="mr-2 txt-lit">Guardar datos en tu agenda click</div>
                        <div>
                          <i className="material-icons lit-ico">person_add</i>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* </Route> */}
          </div>
        </div>
      </div>
      <div id="modalDelete" className="modal">
        <div className="modal-content">
          <h4 className="st mt-2">Borrar prospecto</h4>
          <div className="mb-5">Estas a punto de borrar este prospecto, esta acción no se puede deshacer.</div>
          <div className="row mid-pad">
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">person</i>
              <span>{lead.name}</span>
            </div>
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">email</i>
              <span>{lead.email}</span>
            </div>
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">phone</i>
              <span>{lead.phone}</span>
            </div>
            <div className="col m12 s12 center text-center">
              <p>
                <button
                  className="sq-btn mt-4 mb-4 modal-close"
                  onClick={(e) => {
                    //deleteLead(lead.idLead)
                    deleteRow(e, lead.idLead, lead.leadstatus, 0);
                    changecolumn(!showColumn);
                  }}
                >
                  Borrar
                </button>
              </p>
              <p>
                <button className="gh-btn modal-close waves-effect waves-green">Cancelar</button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="modalSave" className="modal">
        <div className="modal-content">
          <h4 className="st mt-2">Guardar contacto en agenda Click +</h4>
          <div className="mb-1">Asegurate de validar que los datos que proporcionó tu prospecto sean correctos</div>
          <div className="row mid-pad" id="form-lead-show">
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">person</i>
              {/* <span>{lead.name}</span> */}
              <input type="text" name="name" value={lead.name} onChange={editLead} />
            </div>
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">email</i>
              {/* <span>{lead.email}</span> */}
              <input type="text" name="email" value={lead.email} onChange={editLead} />
            </div>
            <div className="col m12 s12 valign-wrapper wht">
              <i className="material-icons prefix ico-grey">phone</i>
              {/* <span>{lead.phone}</span> */}
              <input type="text" name="phone" value={lead.phone} onChange={editLead} />
            </div>
            <div className="col m12 s12 valing-wrapper wht">
              <i className="material-icons prefix ico-grey">info_outline</i>
              <span>Solicitud para seguro de {lead.txtramo}</span>
            </div>
            <div className="col m12 s12 center text-center">
              <p>
                <button
                  className="sq-btn mt-4 mb-4"
                  onClick={(e) => {
                    /* saveLead State */
                    updateAndSend(lead);
                  }}
                >
                  Guardar contacto
                </button>
              </p>
              <p>
                <button className="gh-btn modal-close">Cancelar</button>
              </p>
            </div>
          </div>
          <div className="row mid-pad" id="form-lead-hide">
            <p id="form-lead-text">Espere un momento por favor...</p>
            <button className="gh-btn modal-close" id="form-lead-close" onClick={returnNormal}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
      <div id="modalStatus" className="modal" style={{ width: 35 + "%" }}>
        <div className="modal-content">
          <div className="row mb-0">
            <div className="col m6 s12 ">
              <h4>
                <b> Actualización registro</b>{" "}
              </h4>
            </div>
            <div className="col m6 s12 text-right">
              <span className="gh-btn modal-close">Cerrar</span>
            </div>
          </div>

          <div className="row">
            <div className="col m12 s12">
              <p className="mt-0 mb-0">Selecciona una opción</p>
              <p>
                <label>
                  <input name="group1" type="radio" value="1" checked={statusCont == 1 ? true : false} onChange={(e) => onChangeLead(e, idContacto)} />
                  <span>Por Contactar</span>
                </label>
              </p>
              <p>
                <label>
                  <input name="group1" type="radio" value="2" checked={statusCont == 2 ? true : false} onChange={(e) => onChangeLead(e, idContacto)} />
                  <span>En Seguimiento</span>
                </label>
              </p>
              <p>
                <label>
                  <input name="group1" type="radio" value="3" checked={statusCont == 3 ? true : false} onChange={(e) => onChangeLead(e, idContacto)} />
                  <span>Cerrada Ganada</span>
                </label>
              </p>
              <p>
                <label>
                  <input name="group1" type="radio" value="4" checked={statusCont == 4 ? true : false} onChange={(e) => onChangeLead(e, idContacto)} />
                  <span>Cerrada Perdida</span>
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solicitudes;
