import React, { useState, useEffect, useContext } from "react";
import FormBudgetCarga from "./FormBudgetCarga";
import FormBudgetHogar from "./FormBudgetHogar";
import FormBudgetMedico from "./FormBudgetMedico";
import FormBudgetVehiculo from "./FormBudgetVehiculo";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const FormBudget = ({ flagCotizar, changeCotizar, styleclass, dataAgnFb, subdomain, firebaseUser, publicToken }) => {
	// 1 [ButtonsBudget]
	// 2 [FormDatosContacto with FormResp]
	// 3 [FormAgentCard]

	const { hideWizard } = useContext(ContextGlobal);

	const [formStepContact, changeFSC] = useState({
		formStep: 1,
		data: "",
		style: "show",
	});

	const [seguro, changeSeguro] = useState(1);

	useEffect(() => {
		changeSeguro(1); //Default value

		if (flagCotizar == "hogar") {
			changeSeguro(4);
		}
		if (flagCotizar == "medico") {
			changeSeguro(2);
		}
	});



	const drawForm = (segurovar) => {
		if (segurovar === 1) {
			return (
				<FormBudgetVehiculo
					formStepContact={formStepContact}
					changeFSC={changeFSC}
					changeCotizar={changeCotizar}
					styleclass={styleclass}
					dataAgnFb={dataAgnFb}
					subdomain={subdomain}
					firebaseUser={firebaseUser}
					publicToken={publicToken}
				></FormBudgetVehiculo>
			);
		}
		if (segurovar === 2) {
			return (
				<FormBudgetMedico
					formStepContact={formStepContact}
					changeFSC={changeFSC}
					changeCotizar={changeCotizar}
					styleclass={styleclass}
					dataAgnFb={dataAgnFb}
					subdomain={subdomain}
					firebaseUser={firebaseUser}
					publicToken={publicToken}
				></FormBudgetMedico>
			);
		}
		if (segurovar === 3) {
			return (
				<FormBudgetCarga
					formStepContact={formStepContact}
					changeFSC={changeFSC}
					changeCotizar={changeCotizar}
					styleclass={styleclass}
					dataAgnFb={dataAgnFb}
					subdomain={subdomain}
					firebaseUser={firebaseUser}
					publicToken={publicToken}
				></FormBudgetCarga>
			);
		}
		if (segurovar === 4) {
			return (
				<FormBudgetHogar
					formStepContact={formStepContact}
					changeFSC={changeFSC}
					changeCotizar={changeCotizar}
					styleclass={styleclass}
					dataAgnFb={dataAgnFb}
					subdomain={subdomain}
					firebaseUser={firebaseUser}
					publicToken={publicToken}
				></FormBudgetHogar>
			);
		}
	};

	const ButtonsBudget = ({ formStepContact, seguro, changeCotizar }) => {
		return (
			<>
				{formStepContact.formStep === 1 ? (
					<div className={`row  not-show-at-end ${hideWizard ? "hidden" : "show"}`}>
						<div className="col m6 s12">
							<h3 className="pl-3 st">¿Qué deseas cotizar? </h3>
						</div>
						<div className={`col m6 s12 text-right pt-4 mt-2 `}>
							<button
								className="chip red  white-text "
								onClick={() => {
									changeCotizar("");
								}}
							>
								X Cancelar
							</button>
						</div>
						<div className="col m12 s12 pl-2 mt-4 button-cont flex">
							<button className={`dark-btn chip-clic-plus dark flex-c ${seguro === 1 || seguro === 3 ? "active" : ""}`} onClick={() => changeCotizar("vehiculos")}>
								<i className="material-icons mr-2">directions_car</i>
								<div> Vehículos</div>
							</button>
							<button className={`dark-btn chip-clic-plus dark flex-c ${seguro === 2 ? "active" : ""}`} onClick={() => changeCotizar("medico")}>
								<i className="material-icons mr-2">local_hospital</i> <div>Seguro médico</div>
							</button>
							<button className={`dark-btn chip-clic-plus dark flex-c ${seguro === 4 ? "active" : ""}`} onClick={() => changeCotizar("hogar")}>
								<i className="material-icons mr-2">home</i> <div>Hogar</div>
							</button>
						</div>
					</div>
				) : (
					""
				)}
			</>
		);
	};
	const { pageCTX, changePageCTX } = useContext(ContextGlobal);

	useEffect(() => {
		if (pageCTX === "seguro_medico") {
			changeSeguro(2);
		}
		if (pageCTX === "seguro_hogar") {
			changeSeguro(4);
		}
	});

	return (
		<>
			{pageCTX === null ?
				<>
					{hideWizard ? "" : <ButtonsBudget formStepContact={formStepContact} seguro={seguro} changeCotizar={changeCotizar}></ButtonsBudget>}
				</>
				: ""}

			<div className="">
				<div className="row pt-3 mb-0">{drawForm(seguro)}</div>
			</div>

			{pageCTX === null ?
				<div className="row p-3">
					<div className="col m12 s12 text-center">Powered by Clic Seguros +</div>
				</div>
				: ""}
		</>
	);
};
export default FormBudget;
