import React, { useEffect, useState, useContext } from "react";
import MenuServicioForm from "./MenuServicioForm";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const MenuServicio = ({ styleclass, changeClassStyle, changeProducts, changeFlag, setIsEdited }) => {
  const { dataAgnFb } = useContext(ContextGlobal);
  const { products } = styleclass;
  const [inputText, changeInputTarea] = useState(styleclass.block_title);
  const [inputArea, changeInputArea] = useState(styleclass.block_description);
  const [tmpprod, changeTmpProduct] = useState(products);
  const handleInputTmp = (e) => {
    changeInputTarea(e.target.value);
  };
  const handleInputArea = (e) => {
    changeInputArea(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEdited(true);
    changeClassStyle({
      ...styleclass,
      block_title: inputText,
      block_description: inputArea,
    });
  };

  const updateServicio = (id, title, precio, cobertura, image) => {
    console.log("from local form", title);
    const localitems = tmpprod.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          title: title,
          precio: precio,
          cobertura: cobertura,
          image: image,
        };
      }
      return product;
    });
    changeTmpProduct(localitems);
    changeProducts({
      ...products,
      localitems,
    });
    changeClassStyle({
      ...styleclass,
      products: localitems,
    });
  };

  const validateCheck = (e, id) => {
    changeClassStyle({
      ...styleclass,
      show_image: e.target.checked ? true : false,
    });
  };
  useEffect(() => {
    //console.log("datos desde firebase => ", dataAgnFb);
  });

  return (
    <div className="section">
      <button className="link-back" onClick={() => changeFlag({ isservice: false, iscard: false, iscedula: false })}>
        <h4 className="pad-s">
          <i className="ico-ftl ico-circ wh icon-facebook">&#xf104;</i>
          Regresar al configurador
        </h4>
      </button>
      <h3 className="st center-align">Descripción de productos</h3>

      <div className="b-alert menu-pad">
        <div className="flex-alw">
          <span className="c-blue icon-infob"></span>
          <div className="pad-l1">Pesonaliza los textos que apareceran en tu página, te recomendamos utilizar textos cortos. Si quieres editar la oferta que estas ofrecion visita mis productos</div>
        </div>
      </div>

      <div className="menu-pad dvd-b">
        <div className="switch">
          <label>
            Mostrar imágenes
            <input type="checkbox" checked={styleclass.show_image ? "checked" : ""} onChange={(e) => validateCheck(e)} />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="menu-pad">
        <p className="mb-3">Este texto se mostrará a tus clientes </p>
        <p className="st">Bloque introducción</p>
        <div className="row border mb-4">
          <form action="" onSubmit={handleSubmit}>
            <div className="input-field col m12 s12">
              <label htmlFor="title" className="active">
                Título
              </label>
              <textarea
                className="textarea-serv"
                type="text"
                value={inputText}
                onChange={(e) => {
                  handleInputTmp(e);
                }}
              />
            </div>
            <div className="input-field col m12 s12">
              <textarea
                type="text"
                className="textarea-serv"
                value={inputArea}
                onChange={(e) => {
                  handleInputArea(e);
                }}
              ></textarea>
            </div>
            <div className="col m12 s12 center">
              <button type="submit" className="btn btn-edit-srv normal-text">
                Actualizar
              </button>
            </div>
          </form>
        </div>
        {products.map((item, index) => {
          return <MenuServicioForm key={index} clave={index} item={item} updateServicio={updateServicio} setIsEdited={setIsEdited} />;
        })}
      </div>
    </div>
  );
};

export default MenuServicio;
