import React, { useState, useEffect } from "react";
import { getCompaniesApi, switchCompaniesApi } from "../../api/Admin/companies";
import useAuth from "../../hooks/useAuth";

export default function Companies() {
  const { logout } = useAuth();
  const [companies, setCompanies] = useState(null);
  const [reloadCompanies, setReloadCompanies] = useState(false);

  const handleInput = async (e, companie, status) => {
    status == true ? (status = 0) : (status = 1);
    if (e.target.checked) {
      const response = await switchCompaniesApi(companie, status, logout);
    } else {
      const response = await switchCompaniesApi(companie, status, logout);
    }
    setReloadCompanies(false);
  };

  useEffect(() => {
    (async () => {
      const response = await getCompaniesApi(logout);
      setCompanies(response.DataResponse || []);
      setReloadCompanies(true);
      console.log(response);
    })();
  }, [reloadCompanies]);

  if (!companies) return null;

  return (
    <>
      <div className="c1">
        <div className="card">
          <div className="card-content">
            <div className="box-pad pb-1">
              <div className="search-inpt flex-start-center">
                <span className="material-icons i-search">search</span>
                <input
                  placeholder="Busca un nombre"
                  id=""
                  type="text"
                  className="browser-default"
                  // onChange={(e) => {
                  //   searchByName(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="top-border mb-4"></div>
            <table className="striped centered">
              <thead>
                <tr>
                  <th>Logotipo</th>
                  <th>Nombre</th>
                  <th>Estatus</th>
                  <th>Activar/Desactivar</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((companie, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img className="brand-logo" src={companie.Logo} alt={companie.Nombre} />
                      </td>
                      <td>{companie.Nombre}</td>
                      {companie.Status == true ? (
                        <td className="">
                          <div className="on-status"></div>
                        </td>
                      ) : (
                        <td className="">
                          <div className="off-status"></div>
                          {console.log(companie)}
                        </td>
                      )}
                      <td>
                        <div className="switch s-cent">
                          <label>
                            <input
                              type="checkbox"
                              checked={companie.Status == true ? "checked" : ""}
                              onChange={(e) => {
                                handleInput(e, companie.IDCia, companie.Status);
                              }}
                            />
                            <span className="lever"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
