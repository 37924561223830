import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
//import "firebase/storage";
//import { storage } from "firebase/firestore";
//import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBKp9aoOcpPxIXcXL3N_rMQdRfKHjdNcMw",
  authDomain: "clickplus-2c2d6.firebaseapp.com",
  projectId: "clickplus-2c2d6",
  storageBucket: "clickplus-2c2d6.appspot.com",
  messagingSenderId: "55844328865",
  appId: "1:55844328865:web:846368d7876c34700de7e0",
  measurementId: "G-0XFHEXLCJW",
};

// Initialize Firebase
initializeApp(firebaseConfig);
//const storagelocal = storage();
const storage = getStorage();
export default storage;
