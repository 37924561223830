import { URL_CONSULTA_USERS, PREMIUM_API_PATH, URL_CONSULTA_SERVICIOS, URL_CANCELA_SERVICIOS, URL_REACTIVA_PREMIUM } from "../../utils/constants";
import axios from "axios";
import { authFetch } from "../../utils/fetch";

export async function getAllUsersApi(logout, options = { nameinput: "", statususer: "", page: 1 }) {
  try {
    const url = URL_CONSULTA_USERS;

    let suboptions = { NombreCompleto: "*" + options.nameinput + "*", PageRows: options.page + "|20" };
    if (options.statususer !== "") {
      suboptions = { NombreCompleto: "*" + options.nameinput + "*", Status: options.statususer, PageRows: options.page + "|20" };
    }

    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Filters: suboptions,
      }),
    };
    const result = await authFetch(url, params, logout);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function requestPremiumApi(user, logout) {
  try {
    const url = PREMIUM_API_PATH;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "2",
        Params: { IDVend: user, IDProd: "1" },
      }),
    };
    const result = await authFetch(url, params, logout);
    console.log(user);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getServiceIdApi(user) {
  try {
    const url = URL_CONSULTA_SERVICIOS;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        TypeProcess: "5",
        Filters: {
          IDVend: user,
        },
      }),
    };
    const result = await authFetch(url, params);
    console.log(user);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function cancelPremiumApi(serviceId) {
  try {
    const url = URL_CANCELA_SERVICIOS;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Params: {
          IDService: serviceId,
        },
      }),
    };
    const result = await authFetch(url, params);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function reactivatePremiumApi(serviceId) {
  try {
    const url = URL_REACTIVA_PREMIUM;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: "k2q08aN+zAmcH5/dPMELrQ==",
        Params: {
          IDService: serviceId,
        },
      }),
    };
    const result = authFetch(url, params);
    console.log(result);
    return result ? result : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
