import React from "react";

const ModalGeneric = ({ changeClassStyle, styleclass }) => {
  const acceptEdit = () => {
    changeClassStyle({
      ...styleclass,
      site_is_edited: true,
    });
  };
  return (
    <div id="modalGeneric" className="modal">
      <div className="modal-content">
        <div className="row">
          <div className="col m12 s12 center">
            <h4>Estas a punto de editar los textos predefinidos</h4>
            <div className="row">
              <div className="col m12">
                <img src="/images/advertencia-imagen.png" alt="imagen advertencia" className="full-img" />
              </div>
            </div>
            <p>
              Te recomendamos utilizar los textos que <span className="strong">Click</span> seleccionó para ti, pero puedes cambiarlos si asi lo deseas, sin embargo al cambiarlos no aparecerá el logo
              de Click en tu página.
            </p>
          </div>
          <div className="col m12 s12 center">
            <br />
            <button className="btn btn-primary orange darken-4 w-100 font-light normal-text modal-close" onClick={acceptEdit}>
              Continuar
            </button>
            <br />
            <br />
            <button className="btn btn-default red-text w-100 modal-close ">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalGeneric;
