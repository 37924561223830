import React, { useEffect, useState } from "react";
import { getAllUsersApi, requestPremiumApi, getServiceIdApi, cancelPremiumApi, reactivatePremiumApi } from "../../api/Admin/user";
import { map, size } from "lodash";
import { Preloader } from "react-materialize";
import useAuth from "../../hooks/useAuth";
import db from "../../firebase/firebaseConfig";
import { collection, addDoc, onSnapshot, doc, updateDoc, where, query, getDocs } from "firebase/firestore";

export default function IsPremium() {
  const { logout } = useAuth();
  const [allusers, setAllusers] = useState(null);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [sizeresults, changeSize] = useState(0);
  const [minsize, changeMinSize] = useState(20); //Set equal to user.js
  const [firsttime, changeFT] = useState(true);
  //inputname : "", statususer: "" , page: 1

  const [nameinput, changeName] = useState("");
  const [statususer, changeStatusUser] = useState("");
  const [pageSelected, changePage] = useState(1);

  const showLeadsPaginator = (page) => {
    //let result = pageSelected + page;
    //let newPage = result == 0 ? 1 : result;
    changePage(pageSelected + page);
  };

  const getFirebaseUsers = () => {
    const q = query(collection(db, "cp_agents"), where("agent_url", "==", "chris"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("Este nombre si esta disponible");
      } else {
        // console.log("No esta disponible el nombre");
      }
    });
  };

  const handleInput = async (e, user) => {
    if (e.target.checked) {
      if (user.Status == "Cancelado") {
        const response = await getServiceIdApi(user.IDVend);
        const serviceId = response.DataResponse[0].IDService;
        const reactivateService = await reactivatePremiumApi(serviceId);
        setReloadUsers(true);
      } else {
        const response = await requestPremiumApi(user.IDVend);
        setReloadUsers(true);
        console.log(response);
      }
    } else {
      const response = await getServiceIdApi(user.IDVend);
      const serviceId = response.DataResponse[0].IDService;
      const cancelService = await cancelPremiumApi(serviceId);
      setReloadUsers(true);
      console.log(cancelService);
    }
    setReloadUsers(false);
  };

  /* just one time */
  useEffect(() => {
    //let pageByName = (nameinput !== "") ? changePage(1) : pageSelected;
    //let options = { nameinput: nameinput, statususer: statususer, page: pageByName };
    (async () => {
      const response = await getAllUsersApi(logout);
      setAllusers(response.DataResponse || []);
      //setReloadUsers(true);
      //console.log(response);
    })();
    console.log("Calling the users...");
  }, []);

  /* dispatch eac change */

  useEffect(() => {
    let pageByName = nameinput !== "" ? 1 : pageSelected;

    if (nameinput !== "" && firsttime === true) {
      changePage(1);
      changeFT(false);
      console.log("Primera vez ...");
    }
    if (nameinput !== "" && firsttime === false) {
      pageByName = pageSelected;
      console.log("ya no es primera vez", pageByName);
    }

    if (nameinput === "") {
      changePage(pageSelected);
      changeFT(true);
    }

    let options = { nameinput: nameinput, statususer: statususer, page: pageByName };
    (async () => {
      const response = await getAllUsersApi(logout, options);
      setAllusers(response.DataResponse || []);
      let sizeresp = response.DataResponse || [];
      changeSize(sizeresp.length);
      console.log("Total de usuarios => ", sizeresp.length);
    })();
    console.log("Update the params => ", options);
  }, [nameinput, statususer, pageSelected, reloadUsers]);

  if (!allusers)
    return (
      <div className="c1 flex-c">
        <Preloader active color="blue" flashing={true} size="big" />
      </div>
    );

  return (
    <>
      <div className="c1">
        <div className="card">
          <div className="card-content">
            <div className="box-pad pb-1">
              <div className="search-inpt flex-start-center">
                <span className="material-icons i-search">search</span>
                <input placeholder="Busca un nombre" id="" type="text" className="browser-default" value={nameinput} onChange={(e) => changeName(e.target.value)} />
              </div>
            </div>
            <div className="top-border mb-4">
              <ul className="rqst">
                <li></li>
                <li>
                  <div className="">
                    <label>Estatus</label>
                    <select
                      className="browser-default"
                      onChange={(e) => {
                        changeStatusUser(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="Activo">Activo</option>
                      <option value="Cancelado">Inactivo</option>
                      <option value="Suspendido">Suspendido</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <table className="striped centered">
                <thead>
                  <tr>
                    <th>ID vendedor</th>
                    <th>Nombre</th>
                    <th>Gerencia</th>
                    <th>Línea de negocio</th>
                    <th>Cuenta premium</th>
                    <th>Activar/Desactivar</th>
                  </tr>
                </thead>

                {size(allusers) === 0 ? (
                  <tbody>
                    <tr>
                      <td>No hay usuarios</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {map(allusers, (users, id) => (
                      <tr key={id}>
                        <td>{users.Clave}</td>
                        <td>{users.NombreCompleto}</td>
                        <td>{users.Gerencia}</td>
                        <td>{users.LineBussines}</td>
                        <td>{users.Status}</td>
                        <td>
                          <div className="switch s-cent">
                            <label>
                              <input
                                type="checkbox"
                                checked={users.Status == "Activo" ? "checked" : ""}
                                onChange={(e) => {
                                  handleInput(e, users);
                                }}
                              />
                              <span className="lever"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <div className="row">
                <div className="col m12 s12 text-center mt-3">
                  <button
                    className="btn orange btn-sm ml-2 mr-2"
                    disabled={pageSelected === 1 ? true : false}
                    onClick={() => {
                      showLeadsPaginator(-1);
                    }}
                  >
                    Anterior
                  </button>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    Página {pageSelected}{" "}
                  </span>
                  <button
                    className="btn orange btn-sm ml-2 mr-2"
                    disabled={sizeresults >= minsize ? false : true}
                    onClick={() => {
                      showLeadsPaginator(+1);
                    }}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
