import React from 'react';
const FormBudgetCarga = () => {
    return (
        <>
            <div className="col s12 m12">
                <div className="card radius-card no-padding">
                    <div className="card-content no-padding ">
                        <div className="row">
                            <div className="col s12 m12">
                                <div className="card radius-card mt-0 p-3">
                                    <h3 className="st">Proporciona la siguiente información</h3>
                                    <h5>Esta información es necesaria para poder ofrecerte una cotización a tu medida</h5>
                                </div>
                            </div>
                            <div className="col s12 m12 center p-5">
                                <p>
                                    ...PENDIENTE...
                                </p>
                            </div>
                            <div className="col s12 m12 text-center pt-4 pb-4">
                                <button className="cotiza">Enviar información</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Show hide */}
            <div className="col s12 m12">
                <div className="card radius-card p-3">
                    <p>
                        <span className="card-title"> Estas cotizando </span> <button>Editar información</button>
                    </p>
                </div>
            </div>
        </>
    );
}

export default FormBudgetCarga;