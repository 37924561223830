import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { capitalizeAll, capitalizeFirst } from "../../utils/methods";
import M from "materialize-css/dist/js/materialize.min.js";

const Menu = (props) => {
  const { dataAgnFb, stateCard, menuSetState, menuBtn, user } = props;
  const { premium, logout } = useAuth();

  const todaySpan = () => {
    let today = new Date();
    let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    return <span>{date}</span>;
  };

  const loadAgentCard = () => {
    const showcard = document.getElementById("modalTarjeta");
    const modal = M.Modal.init(showcard, {});
    modal.open();
  };

  if (!user) return null;

  return (
    <div className={!menuBtn ? "menu" : "menu mini"} id="main-menu">
      <div className={!premium ? "header-main" : "header-main-premium"}>
        <div className="flex-c logo-pad">
          <img className="main-logo" src="/images/brand/logo-w.svg" alt=""></img>
        </div>
        <div className="logo-min">
          <img className="full-img" src="/images/logo-minw.svg" alt="" />
        </div>
        <div className="expand">
          <button onClick={() => menuSetState(!menuBtn)}>
            <span className="material-icons circ i-grey z-depth-2">navigate_before</span>
          </button>
        </div>
        <div className="over-avtr">
          <div className="avatar flex-c">{user.Iniciales}</div>
        </div>
      </div>
      <div className="gral-cont">
        <div className="saludo dt-sp">{capitalizeAll(user.Nombre)}</div>
        <div className="access mnt-sp">
          <span>
            Último acceso:
            <br />
            {user.UFAcceso} | {user.UHAcceso}
          </span>
        </div>
      </div>
      <div className="btw-y">
        <div className="menu-top">
          <ul className="main-menu">
            <li className="">
              <NavLink to="/" exact={true}>
                <div className="flex-st-al">
                  <div className="stroke"></div>
                  <span className="material-icons">dashboard</span>
                  <div className="ico-mtxt">Dashboard</div>
                </div>
              </NavLink>
            </li>
            <li className="">
              <NavLink to="/productos">
                <div className="flex-st-al">
                  <div className="stroke"></div>
                  <span className="material-icons">dashboard_customize</span>
                  <div className="ico-mtxt">Mis productos</div>
                </div>
              </NavLink>
            </li>
            <li className="">
              <NavLink to="/solicitudes">
                <div className="flex-st-al">
                  <div className="stroke"></div>
                  <span className="material-icons">notifications</span>
                  <div className="ico-mtxt">Solicitudes</div>
                </div>
              </NavLink>
            </li>

            {dataAgnFb?.agent_url !== undefined && dataAgnFb?.agent_url !== null ? "" : ""}
            {dataAgnFb?.iscomplete !== undefined && dataAgnFb?.iscomplete !== null ? (
              <>
                {dataAgnFb.iscomplete == false ? (
                  ""
                ) : (
                  <>
                    {dataAgnFb?.user_site == undefined || dataAgnFb?.user_site == null ? "" : ""}
                    <li className="">
                      <NavLink to="/custom-site">
                        <div className="flex-st-al">
                          <div className="stroke"></div>
                          <span className="material-icons">palette</span>
                          <div className="ico-mtxt">Apariencia del sitio</div>
                        </div>
                      </NavLink>
                    </li>
                  </>
                )}
              </>
            ) : (
              ""
            )}
            <li className="">
              <div
                className="flex-st-al"
                onClick={() => {
                  loadAgentCard();
                }}
              >
                <div className="stroke"></div>
                <span className="material-icons">person_pin</span>
                <div className="ico-mtxt">Tarjeta virtual</div>
              </div>
            </li>
            {/* <li className="">
              <NavLink to="/admin">
                <div className="flex-st-al">
                  <div className="stroke"></div>
                  <span className="material-icons">settings</span>
                  <div className="ico-mtxt">Administrador</div>
                </div>
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className="menu-bottom">
          {/* <div className="back-btn z-depth-1" id="backTo">
            <img className="full-img" src="images/logo-min.svg" alt="" />
            <span>Regresar a click seguros</span>
          </div> */}
          <div className="back-txt  border-top">
            <button className="logout" onClick={() => logout()}>
              <span className="material-icons">power_settings_new</span>

              <div className="txt-close">Cerrar sesión</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
