//import { Component } from 'react'
import React, { useState, useContext, useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import MenuPortal from "./MenuPortal";
import MainPortal from "./MainPortal";
//import { ContextGlobal } from '../../contexts/ContextGlobal';
import { ContextGlobal } from "../../contexts/ContextGlobal";
import ModalGeneric from "./ModalGeneric";
import MenuServicio from "./MenuServicio";
import MenuFooterCard from "./MenuFooterCard";

import db from "../../firebase/firebaseConfig";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import MenuCedula from "./MenuCedula";

const CustomSite = (props) => {
  const { brandsList, setBrandsList } = props;
  //const ContextGlobal = React.createContext();

  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  //const localDataAgent = localStorage.getItem('dataagent') ? JSON.parse(localStorage.getItem('dataagent')) : [];
  const { dataAgnFb } = useContext(ContextGlobal);

  const [isproduction, chproduction] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  //Global Hooks
  //First item to read in all DOOM

  const [products, changeProducts] = useState([
    {
      id: "item-1",
      name: "Seguro para tu auto",
      icon: "directions_car",
      title: "Cotizar seguro para autos",
      precio: "$3,000",
      tiposervicio: "auto",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Robo total", "RC por daños a terceros", "Defensa legal / jurídica", "Gastos médicos ocupantes"],
      image: "/images/autos-1.jpg",
      status: 1,
      innerpage: "seguro_autos",
    },
    {
      id: "item-2",
      name: "Seguro para motos",
      icon: "motorcycle",
      title: "Cotizar seguro para motocicletas",
      precio: "$3,000",
      tiposervicio: "motos",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/motos-1.jpg",
      status: 1,
      innerpage: "seguro_motos",
    },
    {
      id: "item-3",
      name: "Seguro para Pickup",
      icon: "local_shipping",
      title: "Cotizar seguro para pickup",
      precio: "$3,000",
      tiposervicio: "carga",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Cobertura amplia", "Cobertura limitada", "Responsabilidad Civil"],
      image: "/images/carga-1.jpg",
      status: 1,
      innerpage: "seguro_pickup_particular",
    },
    {
      id: "item-4",
      name: "Seguro Médico",
      icon: "local_hospital",
      title: "Cotizar seguro Médico",
      precio: "$12,000",
      tiposervicio: "medico",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Amplia red de hospitales", "Beneficios a familiares directos", "Variedad en paquetes y coberturas"],
      image: "/images/medicos-1.jpg",
      status: 1,
      innerpage: "seguro_medico",
    },
    {
      id: "item-5",
      name: "Seguro Hogar",
      icon: "home",
      title: "Cotizar seguro de Hogar",
      precio: "$12,000",
      tiposervicio: "hogar",
      cobertura: "con cobertura de responsabilidad civil",
      detalles: ["Coberturas para dentro y fuera de casa", "Responsabilidad civil", "Para viviendas propias o rentadas"],
      image: "/images/hogar-1.jpg",
      status: 1,
      innerpage: "seguro_hogar",
    },
  ]);

  const [styleclass, changeClassStyle] = useState({
    site_is_edited: false,
    menu_side: "horizontal",
    bgPrimary: "back-bl",
    bgSecondary: "back-sec",
    bgBox: "back-lbl",
    btn: "btn-blu",
    fonttype: "inherit", //"font-times","font-monserrat",
    textheader: "",
    block_title: "Aquí puede encontrar los principales ramos que trabajo",
    block_description: "Si no encuentras el seguro que necesitas, contáctame dando click en el botón de abajo",
    imagesproduct: "/images/image-product.png",
    show_image: true,
    show_logotipo: false,
    products: products,
    brands: brandsList,
    urlimage: "",
    contratame: "Contratar el seguro a tu medida es muy sencillo conmigo. Contáctame y te ayudaré a encontrar la mejor opción para ti",
    cedula: "S2147QWRS",
    contitle: "Mucho gusto soy",
    condescription: "tu agente de seguros",
    conimage: "/images/man.jpg",
  });

  const [editServ, changeServ] = useState(false);
  const [editCard, changeCard] = useState(false);
  const [editCedula, changeCedula] = useState(false);

  const [flagBlock, changeFlag] = useState({
    isservice: false,
    iscard: false,
    iscedula: false,
  });

  const [flag, chFlag] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     const response = await getCompaniesApi();
  //     setBrandsList(response?.DataResponse || []);
  //   })();
  // }, []);

  const acceptConditions = () => {};

  const modals = document.querySelectorAll(".modal");

  const updateDataFirebase = () => {
    //Firebase Update
    //console.log(styleclass);
    if (flag === true) {
      //Solo cambia cuando se recibe desde el Menu portal
      const userRef = doc(db, "cp_agents", localDataAgent.document);
      updateDoc(userRef, {
        user_site: styleclass,
      })
        .then(() => {
          //To Do ...
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  };

  const firstTime = () => {
    const userRef = doc(db, "cp_agents", localDataAgent.document);
    updateDoc(userRef, {
      user_site: styleclass,
    })
      .then(() => {
        //To Do ...
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const RenderMenu = () => {
    if (flagBlock.isservice && !flagBlock.iscard && !flagBlock.iscedula) {
      return (
        <MenuServicio
          styleclass={styleclass}
          changeClassStyle={changeClassStyle}
          changeProducts={changeProducts}
          chFlag={chFlag}
          changeFlag={changeFlag}
          setIsEdited={setIsEdited}
          //products={products}
        ></MenuServicio>
      );

      //} else if (!editServ && editCard && !editCedula) {
    } else if (!flagBlock.isservice && flagBlock.iscard && !flagBlock.iscedula) {
      return <MenuFooterCard styleclass={styleclass} changeClassStyle={changeClassStyle} changeFlag={changeFlag}></MenuFooterCard>;
    } else if (!flagBlock.isservice && !flagBlock.iscard && flagBlock.iscedula) {
      //} else if (!editServ && !editCard && editCedula) {
      return <MenuCedula styleclass={styleclass} changeClassStyle={changeClassStyle} changeFlag={changeFlag} />;
    }
  };

  useEffect(() => {
    M.AutoInit();
    M.Modal.init(modals, {
      dismissible: false,
    });
    //console.log("From custom site: ", dataAgnFb);
    //Setting the data from firebase

    if (localDataAgent.document != undefined && localDataAgent.document != null) {
      const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
        const data = doc.data();
        if (data.user_site === undefined || data.user_site === null) {
          //chFlag(true);
          //updateDataFirebase();

          firstTime();
        } else {
          changeProducts(data.user_site.products);
          setBrandsList(data.user_site.brands);
          changeClassStyle(data.user_site);
        }
      });
    }
  }, []);

  useEffect(() => {
    //console.log("Cambio el objeto =>",styleclass);
    updateDataFirebase();
  }, [styleclass]);

  useEffect(() => {
    console.log(isEdited);
  }, [isEdited]);

  return (
    <>
      <div className="mainLy">
        <div className="menuPortal">
          {!flagBlock.isservice && !flagBlock.iscard && !flagBlock.iscedula ? (
            <MenuPortal
              styleclass={styleclass}
              changeClassStyle={changeClassStyle}
              dataAgnFb={dataAgnFb}
              products={products}
              changeProducts={changeProducts}
              brandsList={brandsList}
              setBrandsList={setBrandsList}
              chFlag={chFlag}
            ></MenuPortal>
          ) : (
            <RenderMenu></RenderMenu>
          )}
        </div>
        <div className={styleclass.fonttype + " main-portal"}>
          <MainPortal
            isproduction={isproduction}
            styleclass={styleclass}
            acceptConditions={acceptConditions}
            dataAgnFb={dataAgnFb}
            changeClassStyle={changeClassStyle}
            changeFlag={changeFlag}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
          ></MainPortal>
        </div>
      </div>
      <ModalGeneric changeClassStyle={changeClassStyle} styleclass={styleclass}></ModalGeneric>
    </>
  );
};

export default CustomSite;
