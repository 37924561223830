import React, { useState } from "react";
import SimpleSlider from "./SliderReact";

const SliderArea = ({ styleclass, changeFlag, isproduction }) => {
  const BlockEdit = () => {
    return (
      <div className="edit-frame">
        <button
          className="edit-btn sc-btn"
          onClick={() => {
            changeFlag({ isservice: false, iscard: false, iscedula: true });
          }}
        >
          {" "}
          Editar{" "}
        </button>
      </div>
    );
  };
  return (
    <div className="b-w">
      <div className="g-tit">Trabajo de la mano con las siguientes compañías</div>
      <SimpleSlider brands={styleclass.brands} />

      <div className={styleclass.bgBox + " " + "container-fluid center-align edit"}>
        {/* Este botón de editar solo aparece cuando se personaliza el sitio */}
        {!isproduction && styleclass.site_is_edited ? <BlockEdit /> : ""}
        <div>{styleclass.contratame}</div>
        <div className="ita">Cédula de agente {styleclass.cedula}</div>
      </div>
    </div>
  );
};
export default SliderArea;
