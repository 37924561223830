import React, { useState, useEffect, useContext, useMemo } from "react";
import { PARAMS_PREFIX, LOCAL_URL, ADMIN_URL, PUBLIC_URL } from "../../utils/constants";
import VirtualCard from "../user/VirtualCard";
import { NavLink, Link, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { getAccessToken, getMeApi } from "../../api/sessionInit";
import { setToken, getToken, removeToken, setUserAuthToken, getUserAuthToken } from "../../api/token";
import { getleadStatsApi } from "../../api/Admin/leads";
import { collection, addDoc, onSnapshot, doc, updateDoc, where, query, getDocs } from "firebase/firestore";
import db from "../../firebase/firebaseConfig";
import { getCompaniesApi } from "../../api/Admin/companies";
import { capitalizeAll, capitalizeFirst } from "../../utils/methods";
import AuthContext from "../../contexts/AuthContext";
import Menu from "./Menu";
import CardHome from "./CardHome";
import CustomSite from "../customsite/CustomSite";
import WebSite from "../agentweb/WebSite";
import Solicitudes from "./Solicitudes";
import Productos from "./Productos";
import CardSolicitudes from "./CardSolicitudes";
import Premium from "./Premium";
import CancelPremium from "./CancelPremium";
import ShowCardAgent from "./ShowCardAgent";
import Admin from "../admin/Admin";
import M from "materialize-css/dist/js/materialize.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SolicitudesPapelera from "./SolicitudesPapelera";
import Aviso from "../customsite/Aviso";
import { getAuth, signInAnonymously } from "firebase/auth";

const App = () => {
  const { styleclass, stateCard, dataAgnFb, setStepCard, changeDataAgnFb } = useContext(ContextGlobal);
  const { search } = useLocation();
  const [products, changeProducts] = useState([{}]);
  const [editarOpt, changeEditarOpt] = useState(1);
  const [accessToken, setAccessToken] = useState(null);
  const [auth, setAuth] = useState(undefined);
  const [user, setUser] = useState(null);
  const [premium, setPremium] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [reloadModal, setReloadModal] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [reloadCompanies, setReloadCompanies] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [leadStats, setLeadStats] = useState([]);
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  const [dataAgent, changeDataAgent] = useState(localDataAgent);
  const [stepForm, stepFormChange] = useState(localDataAgent.length === 0 ? 1 : localDataAgent.step);

  let history = useHistory();
  const loadedUrl = window.location.href;
  const hostUrl = window.location.host;
  const meToken = getUserAuthToken();
  const urlArr = hostUrl.split(".");
  const subdomain = hostUrl.split(".")[0];
  const [showAlert, changeAlert] = useState(false);
  //#console.log(subdomain);
  //#console.log(urlArr);
  //#console.log(user);

  useEffect(() => {
    //#console.log(`cambio estado ${stepForm}`);
  }, [stepForm]);

  useEffect(() => {
    console.log("INICIANDO SESION:::");

    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in...
        console.log("Se ha iniciado sesion::::");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }, []);

  /* url logic */
  useEffect(() => {
    /* domain validation */
    if (loadedUrl.includes(ADMIN_URL) || loadedUrl.includes(LOCAL_URL)) {
      /* URL has params?*/
      if (loadedUrl.includes(PARAMS_PREFIX)) {
        /* URL has params */
        const urlsplit = loadedUrl.split(PARAMS_PREFIX);
        const userToken = urlsplit[1];
        //#console.log("has params");

        /*validation on localstorage */
        if (meToken == "undefined" || meToken == null || meToken == undefined || meToken == []) {
          //#console.log("There isn't user_auth_token");
          if (dataAgent == [] || dataAgent == null || dataAgent.length == 0) {
            login(userToken);
          } else {
            //#console.log("No token, no dataAgent");
          }
        } else {
          //#console.log("there is user_auth_token");
          if (meToken == userToken) {
            if (dataAgent == [] || dataAgent == null || dataAgent.length == 0) {
              login(userToken);
            } else {
              //#console.log("same user");
            }
          } else {
            reload(userToken);

            //#console.log("there is token, but not the same as localstorage");
          }
        }
        /*end of validation on localstorage */
        setUserAuthToken(userToken);
      } else {
        /* URL has not params */
        //#console.log("hasn't params");
        if (meToken == "undefined" || meToken == null || meToken == undefined || meToken == []) {
          //#console.log("No user_auth_token");
          if (dataAgent == [] || dataAgent == null || dataAgent.length == 0) {
            if (urlArr.length == 2) {
              //#console.log(subdomain);
            } else {
              //window.location.href = "https://clicksegurosvip.com";
            }
          } else {
            window.location.href = `${loadedUrl}?t=${dataAgent.token_url}`;
          }
        } else {
          //#console.log("logged user");
          if (dataAgent == [] || dataAgent == null || dataAgent.length == 0) {
            window.location.href = `${loadedUrl}?t=${meToken}`;
          }
        }
      }
    } else {
      //#console.log("no valid domain");
    }
    /* end domain validation */
  }, []);

  const modals = document.querySelectorAll(".modal");
  /* end url logic */

  const Alerta = () => {
    return (
      <>
        <p className="red-text center text-center">
          DEBUG MODE: No hay información asociada a esta URL vacía, <br /> descomentar la linea 126 de App.js para realizar la redireccion correspondiente.
        </p>
      </>
    );
  };

  /* Hacer la instancia del documento en la cookie */

  useEffect(() => {
    localStorage.setItem("dataagent", JSON.stringify(dataAgent));
  }, [dataAgent]);

  // Auth process
  useEffect(() => {
    (async () => {
      const response = await getAccessToken();
      setAccessToken(response.Token);
      console.log("version 1.0.6");
    })();
  }, []);

  useEffect(() => {
    if (accessToken) {
      setToken(accessToken);
      setAuth({
        accessToken,
      });
    } else {
      setAuth(null);
    }
    setReloadUser(false);
  }, [accessToken]);

  useEffect(() => {
    (async () => {
      const response = await getMeApi(meToken, logout);
      setUser(response);
      //#console.log(response);
      if (response?.IsPremium == "Activo") {
        setPremium(true);
      } else {
        setPremium(false);
      }
    })();
  }, [auth, premium]);

  useEffect(() => {
    if (user != null) {
      setUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    if (userLoaded == true) {
      (async () => {
        const response = await getleadStatsApi(user.Clave, logout);
        if (response.Success == true) {
          setLeadStats(response.DataResponse);
        }
      })();
    }
  }, [userLoaded]);

  useEffect(() => {
    if (user) {
      //#console.log("login");
      const q = query(collection(db, "cp_agents"), where("IdVend", "==", user.IdVend));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          //#console.log("::: desde useEffect removeAndUpdate => No esta registrado, se debe registrar en VCARD :::");
          //#console.log(`dese Use Effect ${user.IdVend}`);
          stepFormChange(1);
          changeDataAgent([]);
          changeDataAgnFb({});
          setStepCard(0, false);
          setReloadModal(true);
        } else {
          if (user.IdVend === 0 || user.IdVend == "0" || user.IdVend == 0) {
            //Cuando solo llega en vacio
            changeAlert(true);
          } else {
            changeAlert(false);
            //#console.log("::: removeAndUpdate => Si está registrado, se procede a generar la sesion :::");
            querySnapshot.forEach((doc) => {
              let final_object = { document: doc.id, ...doc.data() };
              changeDataAgent(final_object);
              changeDataAgnFb(final_object);
              setStepCard(final_object.step, final_object.iscomplete);
              setReloadModal(true);
              //alert("Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados");
              //#console.log("::: removeAndUpdate => Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados :::");
              // window.location.reload();
            });
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  const login = (userToken) => {
    (async () => {
      const response = await getMeApi(meToken, logout);
      console.log("DATOS DEL USUARIO LOGIN EFFECT", response);
      setUser(response);
      if (response?.IsPremium == "Activo") {
        setPremium(true);
      } else {
        setPremium(false);
      }
    })();
    //#console.log("login");
    if (user) {
      const q = query(collection(db, "cp_agents"), where("IdVend", "==", user.IdVend));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          //#console.log("::: removeAndUpdate => No esta registrado, se debe registrar en VCARD :::");
          stepFormChange(1);
          changeDataAgent([]);
          changeDataAgnFb({});
          setStepCard(0, false);
          setReloadModal(true);
        } else {
          //#console.log("::: removeAndUpdate => Si está registrado, se procede a generar la sesion :::");

          querySnapshot.forEach((doc) => {
            let final_object = { document: doc.id, ...doc.data() };
            changeDataAgent(final_object);
            changeDataAgnFb(final_object);
            setStepCard(final_object.step, final_object.iscomplete);
            setReloadModal(true);
            //alert("Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados");
            //#console.log("::: removeAndUpdate => Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados :::");
            // window.location.reload();
          });
        }
      });
      return () => {
        unsubscribe();
      };
    }
  };

  const reload = (userToken) => {
    (async () => {
      const response = await getMeApi(meToken, logout);
      setUser(response);
      //#console.log(response);
      if (response?.IsPremium == "Activo") {
        setPremium(true);
      } else {
        setPremium(false);
      }
    })();
    //#console.log("reloaduser");
    if (user) {
      const q = query(collection(db, "cp_agents"), where("IdVend", "==", user.IdVend));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          //#console.log("::: removeAndUpdate => No esta registrado, se debe registrar en VCARD :::");
          stepFormChange(1);
          changeDataAgent([]);
          changeDataAgnFb({});
          setStepCard(0, false);
          setReloadModal(true);
        } else {
          //#console.log("::: removeAndUpdate => Si está registrado, se procede a generar la sesion :::");

          querySnapshot.forEach((doc) => {
            let final_object = { document: doc.id, ...doc.data() };
            changeDataAgent(final_object);
            changeDataAgnFb(final_object);
            setStepCard(final_object.step, final_object.iscomplete);
            setReloadModal(true);
            window.location.reload();
            //alert("Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados");
            //#console.log("::: removeAndUpdate => Hemos detectado que cambiaste de perfil, vamos a cargar los datos asociados :::");
          });
        }
      });
      return () => {
        unsubscribe();
      };
    }
  };

  const logout = () => {
    stepFormChange(1);
    changeDataAgent([]);
    changeDataAgnFb({});
    setStepCard(0, false);
    if (auth) {
      removeToken();
      setAuth(null);
      window.location.href = "https://clicksegurosvip.com";
    }
  };

  const authData = useMemo(
    () => ({
      auth,
      login,
      logout,
      setReloadUser,
      user,
      premium,
      setPremium,
    }),
    [auth, user]
  );

  // End Auth process

  useEffect(() => {
    if (dataAgnFb?.user_site !== undefined && dataAgnFb?.user_site !== null) {
      {
        changeProducts(dataAgnFb.user_site.products);
      }
    }
  });

  const changeOpt = (opt) => {
    //setStepCard(opt,false);
    changeEditarOpt(opt);
  };

  const [menuBtn, menuSetState] = useState(false);

  // Modals init

  useEffect(() => {
    M.AutoInit(); //If the view is new use this always
    M.Modal.init(modals, {
      dismissible: false,
    });
    //changeLeads(notifications);
  }, [user]);

  useEffect(() => {
    (async () => {
      const response = await getCompaniesApi(logout);
      setBrandsList(response?.DataResponse || []);
      //setBrandsList([]);

      // setReloadCompanies(true);
    })();
    setReloadCompanies(false);
  }, [reloadCompanies]);
  ////#console.log(user);

  // End Modals init
  if (!user) return null;

  return (
    <AuthContext.Provider value={authData}>
      {loadedUrl.includes(PUBLIC_URL) ? (
        <WebSite subdomain={subdomain}></WebSite>
      ) : (
        <>
          <div className="fullwidth">
            {showAlert ? <Alerta /> : ""}
            <Switch>
              <Route path="/" exact={true}>
                <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
                  <div className="cont-menu">
                    <Menu stateCard={stateCard} dataAgnFb={dataAgnFb} menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
                  </div>
                  <div className="main-container">
                    <div className="c1">
                      <div className="card-clickb back-w z-depth-2">
                        <div className="flex-btw-btm">
                          <div>
                            <h5 className="st flex-ico">
                              <span className="material-icons r-ico i-red">language</span>
                              Mis páginas
                            </h5>
                            <div className="flex-start-center">
                              <h5 className="st grey-txt">{capitalizeAll(user.NombreCompleto)}</h5>
                            </div>
                            <div className="flex-start-center mt-1">
                              {dataAgnFb?.iscomplete !== undefined && dataAgnFb?.iscomplete !== null ? (
                                <>
                                  {dataAgnFb.iscomplete == false ? (
                                    <div>
                                      <span className="material-icons r-ico i-red">warning</span>
                                      Debes completar tu tarjeta virtual para visualizar tu página
                                    </div>
                                  ) : (
                                    <>
                                      {dataAgnFb?.user_site == undefined || dataAgnFb?.user_site == null ? (
                                        ""
                                      ) : (
                                        <Link className="sc-btn" to={`/${dataAgnFb?.agent_url}`} target="_blank">
                                          Ver sitio{" "}
                                        </Link>
                                      )}
                                      <NavLink to="/custom-site" className="light-blue-btn spc-left ">
                                        {" "}
                                        Editar apariencia del sitio
                                      </NavLink>
                                    </>
                                  )}
                                </>
                              ) : (
                                <span className="red-text">
                                  <span className="material-icons r-ico i-red">warning</span>Debes completar tu tarjeta virtual para visualizar tu página
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex-end">
                            {user.IsPremium == "Cancelado" ? (
                              ""
                            ) : !premium ? (
                              <button className="premium-btn flex-center-alw modal-trigger" href="#modal-premium">
                                <img className="ico-premium" src="images/diamond.svg" alt="" />
                                Mejora tu cuenta a premium
                              </button>
                            ) : (
                              <button className="premium-btn flex-center-alw modal-trigger" href="#modal-cancel-premium">
                                <img className="ico-premium" src="images/diamond.svg" alt="" />
                                Cancelar premium
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c2">
                      <CardHome stateCard={stateCard} dataAgnFb={dataAgnFb} changeOpt={changeOpt} setReloadCompanies={setReloadCompanies} stepFormChange={stepFormChange} />
                    </div>
                    <div className="c3">
                      <CardSolicitudes />
                    </div>
                    <div className="c4">
                      <div className="card-click z-depth-2">
                        <h5 className="st flex-ico mb-3">
                          <span className="material-icons r-ico i-red">insights</span>
                          Estadísticas
                        </h5>
                        <div className="cont-st"></div>
                        <div className="cont-st">
                          <div className="txt-st">Solicitudes de cotización en proceso</div>
                          <div className="num-st">0</div>
                        </div>
                        <div className="cont-st">
                          <div className="txt-st">Cotizaciones ganadas</div>
                          {leadStats.length > 0 ? <div className="num-st">{leadStats.Total_CerradaG}</div> : <div className="num-st">0</div>}
                        </div>
                      </div>
                    </div>
                    <div className="c5">
                      <div className="card-click z-depth-2">
                        <h5 className="st flex-ico mb-3">
                          <span className="material-icons r-ico i-red">dashboard_customize</span>
                          Mis productos
                        </h5>
                        {dataAgnFb?.user_site !== undefined && dataAgnFb?.user_site !== null ? (
                          <>
                            {products.map((item, index) => {
                              if (item.status === 1) {
                                return (
                                  <div className="flex-alw mb-3" key={index}>
                                    <i className="material-icons circ-formw i-black">{item.icon}</i>
                                    <span className="prod-name pl-2">{item.name}</span>
                                  </div>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="add-srv mt-3">
                          <NavLink to="/productos" className="flex-alw">
                            <span className="material-icons r-ico i-red">add</span>
                            Agregar otro
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/solicitudespapelera">
                <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
                  <div className="cont-menu">
                    <Menu stateCard={stateCard} dataAgnFb={dataAgnFb} menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
                  </div>
                  <div className="main-container">
                    <div className="c1">
                      <SolicitudesPapelera></SolicitudesPapelera>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/solicitudes">
                <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
                  <div className="cont-menu">
                    <Menu stateCard={stateCard} dataAgnFb={dataAgnFb} menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
                  </div>
                  <div className="main-container">
                    <div className="c1">
                      <Solicitudes></Solicitudes>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/productos">
                <div className={!menuBtn ? "main-wrapper" : "main-wrapper small"}>
                  <div className="cont-menu">
                    <Menu stateCard={stateCard} dataAgnFb={dataAgnFb} menuBtn={menuBtn} menuSetState={menuSetState} user={user} />
                  </div>
                  <div className="main-container">
                    <div className="c1">
                      <Productos></Productos>
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/custom-site">
                <CustomSite brandsList={brandsList} setBrandsList={setBrandsList}></CustomSite>
              </Route>
              <Route path="/admin">
                <Admin></Admin>
              </Route>
              <Route path="/:nameurl">
                <WebSite></WebSite>
              </Route>
            </Switch>
          </div>
          <VirtualCard editarOpt={editarOpt} user={user} setReloadCompanies={setReloadCompanies} stepForm={stepForm} stepFormChange={stepFormChange} />
          <ShowCardAgent stateCard={stateCard} dataAgnFb={dataAgnFb} />
          <CancelPremium />
          <Premium />
          <Aviso />
        </>
      )}
    </AuthContext.Provider>
  );
};

export default App;
