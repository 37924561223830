import { useEffect } from "react";
import PremiumDetail from "./PremiumDetail";
import WelcomePremium from "./WelcomePremium";
import PremiumLegal from "./PremiumLegal";
import useAuth from "../../hooks/useAuth";

export default function Premium() {
  const { user } = useAuth();
  /*APi premium*/
  const addPremium = async () => {
    // const response = await requestPremiumApi(user)
    console.log(user);
  };

  /*fin APi premium*/
  return (
    <>
      <div id="modal-premium" className="modal">
        <div className="modal-content">
          <div className="flex-center-alw"></div>
          <span className="modal-close top-left pointer">
            Cerrar
          </span>
          <h5 className="st text-center">Suscríbete a Click+ Premium</h5>
          <div className="modal-header">
            <img className="full-img" src="/images/back-diamond.jpg" alt="" />
          </div>
          <div className="row mid-pad">
            <h5 className="st text-center">Por solo $100 semanales</h5>
            <p className="small-txt text-center">Se retiene de tus comisiones y lo verás reflejado en tu estado de cuenta. Puedes cancelarlo en cualquier momento.</p>
            <div className="mt-3">
              <div className="flex-alw">
                <span className="material-icons">touch_app</span>
                <div className="ico-mtxt">
                  <p className="st">Permite a tus clientes cotizar en línea automóviles y vida</p>
                  <p className="small-txt">La tecnología que ya conoces en Click Seguros ahora en tu pagina personal.</p>
                </div>
              </div>
              <div className="flex-alw mt-3">
                <div className="ico-regular">
                  <img className="full-img" src="/images/whatsapp.svg" alt="" />
                </div>
                <div className="ico-mtxt">
                  <p className="st">Podrás poner tu whatsapp como contacto</p>
                  <p className="small-txt">Ayuda a tus clientes a tener un contacto directo contigo publicando tu número de whatsapp desde tu página personal.</p>
                </div>
              </div>
              <div className="flex-alw mt-3">
                <span className="material-icons">style</span>
                <div className="ico-mtxt">
                  <p className="st">Amplía tus herramientas de personalización</p>
                  <p className="small-txt">Más colores, más tipografías, más estilos de plantilla, una página personal extra y más espacio en tu mail.</p>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center ">
              <button className="waves-effect sq-btn darken-1 white-text modal-trigger  modal-close" href="#modal-legal-premium">
                Contrata Premium
              </button>
              <button className="gh-btn mt-3 modal-trigger modal-close" href="#modal-premium-detail">
                Más información
              </button>
            </div>
          </div>
        </div>
      </div>
      <PremiumDetail />
      <PremiumLegal />
      <WelcomePremium />
    </>
  );
}
