import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import M from "materialize-css/dist/js/materialize.min.js";

const MenuServicioForm = ({ clave, item, updateServicio, chFlag, setIsEdited }) => {
  const { user } = useAuth();

  const [imagesService, changeImagesService] = useState([
    {
      tiposervicio: "auto",
      images: ["/images/autos-1.jpg", "/images/autos-2.jpg", "/images/autos-3.jpg", "/images/autos-4.jpg", "/images/autos-5.jpg"],
    },

    {
      tiposervicio: "motos",
      images: ["/images/motos-1.jpg", "/images/motos-2.jpg", "/images/motos-3.jpg", "/images/motos-4.jpg", "/images/motos-5.jpg"],
    },

    {
      tiposervicio: "carga",
      images: ["/images/carga-1.jpg", "/images/carga-2.jpg", "/images/carga-3.jpg", "/images/carga-4.jpg", "/images/carga-5.jpg"],
    },

    {
      tiposervicio: "medico",
      images: ["/images/medicos-1.jpg", "/images/medicos-2.jpg", "/images/medicos-3.jpg", "/images/medicos-4.jpg", "/images/medicos-5.jpg"],
    },
    {
      tiposervicio: "hogar",
      images: ["/images/hogar-1.jpg", "/images/hogar-2.jpg", "/images/hogar-3.jpg", "/images/hogar-4.jpg", "/images/hogar-5.jpg"],
    },
  ]);

  const [imagesServicePremium, changeImagesServicePremium] = useState([
    {
      tiposervicio: "auto",
      images: ["/images/autos-6.jpg", "/images/autos-7.jpg", "/images/autos-8.jpg", "/images/autos-9.jpg", "/images/autos-10.jpg"],
    },

    {
      tiposervicio: "motos",
      images: ["/images/motos-6.jpg", "/images/motos-7.jpg", "/images/motos-8.jpg", "/images/motos-9.jpg", "/images/motos-10.jpg"],
    },

    {
      tiposervicio: "carga",
      images: ["/images/carga-6.jpg", "/images/carga-7.jpg", "/images/carga-8.jpg", "/images/carga-9.jpg", "/images/carga-10.jpg"],
    },

    {
      tiposervicio: "medico",
      images: ["/images/medicos-6.jpg", "/images/medicos-7.jpg", "/images/medicos-8.jpg", "/images/medicos-9.jpg", "/images/medicos-10.jpg"],
    },
    {
      tiposervicio: "hogar",
      images: ["/images/hogar-6.jpg", "/images/hogar-7.jpg", "/images/hogar-8.jpg", "/images/hogar-9.jpg", "/images/hogar-10.jpg"],
    },
  ]);

  const [title, changeTitle] = useState(item.title);
  const [precio, changePrecio] = useState(item.precio);
  const [cobertura, changeCobertura] = useState(item.cobertura);
  const [image, changeImage] = useState(item.image);

  const { tiposervicio } = item;

  //console.log(`hola ${tiposervicio}`);

  //console.log("servicio actual =>", tiposervicio);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEdited(true);
    updateServicio(item.id, title, precio, cobertura, image);
  };

  const handleText = (e) => {
    //console.log(e.target.name);
    let value = e.target.value;
    switch (e.target.name) {
      case "title":
        changeTitle(value);
        //console.log("title =>",value);
        break;

      case "precio":
        changePrecio(value);
        //console.log("precio =>",value);
        break;

      case "cobertura":
        changeCobertura(value);
        //console.log("cobertura =>",value);
        break;
    }
  };

  const handleRadio = (e, premiumoption) => {
    let value = e.target.value;
    console.log("is selected", value);
    console.log("Es una opcion premium ?", premiumoption);
    if (user.IsPremium != "Activo" && premiumoption == true) {
      const showcard = document.getElementById("modal-premium");
      const modal = M.Modal.init(showcard, {});
      modal.open();
      return false;
    } else {
      changeImage(value);
    }
  };

  const imagesViewList = (dataimg, premiumoption = false) => {
    return dataimg.map((item, index) => {
      //console.log(item);
      return (
        <div className="col m4 s4" key={index}>
          <label>
            <img src={item} className="responsive-img" />
            <input
              type="radio"
              name="radio-img"
              value={item}
              checked={image === item ? true : false}
              onChange={(e) => {
                handleRadio(e, premiumoption);
              }}
            />
            <span>Img - {premiumoption ? "prem." + index : index}</span>
          </label>
        </div>
      );
    });
  };

  return (
    <div>
      <p className="st">{title}</p>
      <div className="row border mb-4">
        <form key={clave} onSubmit={handleSubmit}>
          <div className="input-field col m12 s12">
            <label htmlFor="title" className="active">
              Título
            </label>
            <textarea className="textarea-serv" id="title" type="text" value={title} onChange={handleText} name="title" />
          </div>
          <div className="input-field col m12 s12">
            <label className="active">Precio</label>
            <input type="text" value={precio} onChange={handleText} name="precio" />
          </div>
          <div className="input-field col m12 s12">
            <label className="active">Descripción</label>
            <textarea className="textarea-serv" type="text" value={cobertura} onChange={handleText} name="cobertura" />
          </div>
          <div className="row mb-5">
            <p>Selecciona una imagen</p>

            {imagesService.map((item, index) => {
              return item.tiposervicio === tiposervicio ? imagesViewList(item.images) : "";
            })}
          </div>

          <div className="row mb-5">
            <p>Selecciona una imagen (Premium)</p>
            {imagesServicePremium.map((item, index) => {
              return item.tiposervicio === tiposervicio ? imagesViewList(item.images, true) : "";
            })}
          </div>
          <div className="col m12 s12 center">
            <button className="btn btn-edit-srv normal-text" type="submit">
              Actualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MenuServicioForm;
