import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { capitalizeAll, capitalizeFirst } from "../../utils/methods";

export default function MenuLeads(props) {
  const { menuSetState, menuBtn } = props;
  const [user, setUser] = useState({
    Iniciales: "ADM",
  });

  return (
    <div className={!menuBtn ? "menu" : "menu mini"} id="main-menu">
      <div className="header-main">
        <div className="flex-c logo-pad">
          <img className="main-logo" src="/images/brand/logo-w.svg" alt=""></img>
        </div>
        <div className="logo-min">
          <img className="full-img" src="/images/logo-minw.svg" alt="" />
        </div>
        <div className="expand">
          <button onClick={() => menuSetState(!menuBtn)}>
            <span className="material-icons circ i-grey z-depth-2">navigate_before</span>
          </button>
        </div>
        <div className="over-avtr">
          <div className="avatar flex-c">{user.Iniciales}</div>
        </div>
      </div>
      <div className="gral-cont">
        <div className="saludo dt-sp">{capitalizeAll(user.Nombre)}</div>
        {/* <div className="access mnt-sp">
          <span>
            Último acceso:
            <br />
            {user.UFAcceso} | {user.UHAcceso}
          </span>
        </div> */}
      </div>
      <div className="btw-y">
        <div className="menu-top">
          <div className="menu-leads">
            <ul className="main-menu">
              <li>
                <NavLink exact to="/admin">
                  <div className="flex-st-al">
                    <div className="stroke"></div>
                    <span className="material-icons">dashboard</span>
                    <div className="ico-mtxt">Dashboard</div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/getleads">
                  <div className="flex-st-al">
                    <div className="stroke"></div>
                    <span className="material-icons">notifications</span>
                    <div className="ico-mtxt">Leads x usuario</div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/ispremium">
                  <div className="flex-st-al">
                    <div className="stroke"></div>
                    <span className="material-icons">diamond</span>
                    <div className="ico-mtxt">Usuarios premium</div>
                  </div>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/admin/catalogo">
                  <div className="flex-st-al">
                    <div className="stroke"></div>
                    <span className="material-icons">playlist_add</span>
                    <div className="ico-mtxt">Catálogo de servicios</div>
                  </div>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/admin/companies">
                  <div className="flex-st-al">
                    <div className="stroke"></div>
                    <span className="material-icons">business</span>
                    <div className="ico-mtxt">Compañias</div>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-bottom">
          <div className="back-btn z-depth-1" id="backTo">
            <img className="full-img" src="images/logo-min.svg" alt="" />
            <span>Regresar a click seguros</span>
          </div>
          <div className="back-txt">
            <div className="mb-1">
              <span className="material-icons circ-l i-grey">power_settings_new</span>
            </div>
            <div className="txt-close">Cerrar sesión</div>
          </div>
        </div>
      </div>
    </div>
  );
}
