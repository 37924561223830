import React, { useEffect, useContext, useState } from "react";
import db from "../../firebase/firebaseConfig";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { ContextGlobal } from "../../contexts/ContextGlobal";

const Productos = () => {
  const localDataAgent = localStorage.getItem("dataagent") ? JSON.parse(localStorage.getItem("dataagent")) : [];
  const { styleclass, dataAgnFb } = useContext(ContextGlobal);

  //changeProducts(data.user_site.products);

  // 1.- Usamos la variable que viene de styleclass pero viene con valores por defecto en caso de que no tenga ni madres en firebase

  const [tmpstyle, chTmpStyle] = useState(styleclass);
  //const [products, changeProducts] = useState([{}]);
  const [products, changeProducts] = useState(dataAgnFb.user_site != undefined || dataAgnFb.user_site != null ? [{}] : tmpstyle.products);

  const [flag, chFlag] = useState(false);
  const [cards, showCards] = useState("auto");

  const firstTime = () => {
    console.log("Cargando información...");
    const userRef = doc(db, "cp_agents", localDataAgent.document);
    updateDoc(userRef, {
      user_site: styleclass,
    })
      .then(() => {
        //To Do ...
        console.log("Se termino el proceso ");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const chBrand = (e, id) => {
    chFlag(true);
    //console.log("item id", id);
    const tmpProducts = products.map((prod) => {
      if (prod.id == id) {
        return { ...prod, status: e.target.checked ? 1 : 0 };
      }
      return prod;
    });
    changeProducts(tmpProducts);
    chTmpStyle({
      ...tmpstyle,
      products: tmpProducts,
    });
  };

  const existOnFireBase = (id) => {
    //Cambiar el stylo si existe
    //return userSite
  };

  const updateDataFirebase = () => {
    //Firebase Update
    //console.log("From Products ...", tmpstyle);

    if (flag === true) {
      //console.log("Cambio la bandera ...", tmpstyle);

      //Solo cambia cuando se recibe desde el Menu portal
      const userRef = doc(db, "cp_agents", localDataAgent.document);
      updateDoc(userRef, {
        user_site: tmpstyle,
      })
        .then(() => {
          console.log("Se ha hecho cambios en firebase");
          chFlag(false);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    }
  };

  useEffect(() => {
    if (localDataAgent.document != undefined && localDataAgent.document != null) {
      const unsub = onSnapshot(doc(db, "cp_agents", localDataAgent.document), (doc) => {
        const data = doc.data();
        if (data.user_site === undefined || data.user_site === null) {
          console.log("No hay nada definido en firebase...");
          //firstTime();
        } else {
          chTmpStyle(data.user_site);
          changeProducts(data.user_site.products);
        }
      });
    }
  }, []);

  useEffect(() => {
    //console.log("Cambio el objeto =>",styleclass);
    //console.log("Entro la bandera...")
    //chFlag(true);
    updateDataFirebase();
  }, [products]);

  return (
    <>
      <div className="row">
        <div className="col m12 s12">
          <div className="card no-depth z-depth-3 pb-5">
            <div className="card-content pt-0 pb-0">
              <div className="row">
                <div className="col m12 s12 mt-3">
                  <h5 className="st flex-ico">
                    <span className="material-icons r-ico i-red">language</span>
                    Mis productos
                  </h5>
                </div>

                {dataAgnFb.user_site !== undefined && dataAgnFb.user_site !== null ? (
                  <>
                    {/* buttons */}
                    <div className="col m12 s12 mb-3">
                      <div className="col m12 s12 pl-2 mt-4 button-cont flex">
                        <button
                          className="dark-btn chip-clic-plus dark flex-c"
                          onClick={() => {
                            showCards("auto");
                          }}
                        >
                          <i className="material-icons mr-2">directions_car</i> Vehículos
                        </button>
                        <button
                          className="dark-btn chip-clic-plus dark flex-c"
                          onClick={() => {
                            showCards("motos");
                          }}
                        >
                          <i className="material-icons mr-2">motorcycle</i> Motocicletas
                        </button>
                        <button
                          className="dark-btn chip-clic-plus dark flex-c"
                          onClick={() => {
                            showCards("carga");
                          }}
                        >
                          <i className="material-icons mr-2">local_shipping</i> Pickup
                        </button>
                        <button
                          className="dark-btn chip-clic-plus dark flex-c"
                          onClick={() => {
                            showCards("medico");
                          }}
                        >
                          <i className="material-icons mr-2">local_hospital</i> Seguro médico
                        </button>
                        <button
                          className="dark-btn chip-clic-plus dark flex-c"
                          onClick={() => {
                            showCards("hogar");
                          }}
                        >
                          <i className="material-icons mr-2">home</i> Hogar
                        </button>
                      </div>
                    </div>
                    {/* cards */}
                    {products.map((item, index) => {
                      return (
                        <div className={item.tiposervicio == cards ? "col m12 s12 show" : "col m12 s12 hidden"} key={index}>
                          <div className="card">
                            <div className="">
                              <div className="row flex">
                                <div className="col m2 card-product l-grey btw">
                                  <div className="ico-cont ">
                                    <i className="material-icons circ-formw i-black">{item.icon}</i>
                                    <div className="prod-name">{item.name}</div>
                                  </div>
                                  <div className="ico-cont">
                                    <div className="switch s-cent">
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={item.status == 1 ? true : false}
                                          onChange={(e) => {
                                            chBrand(e, item.id);
                                          }}
                                          onClick={(e) => {}}
                                        />
                                        <span className="lever"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col m10 btw m-grey two-pad">
                                  <div className="">
                                    <div className="txt-lit">Nombre de este ramo</div>
                                    <div className="st">{item.name}</div>
                                  </div>

                                  <div>
                                    <div className="box-bl mt-3">
                                      <label>
                                        <input type="radio" className="with-gap" disabled />
                                        <span>Permitir al cliente cotizar</span>
                                      </label>
                                    </div>

                                    <div className="box-bl z-depth-3 mt-2">
                                      <label>
                                        <input type="radio" className="with-gap" onChange={() => console.log("hola")} checked />
                                        <span>Solicitar únicamente datos</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="row">
                    <div className="col m12 s12 text-center center mb-5">
                      <span className="red-text">
                        <b>
                          Debes llenar tu tarjeta virtual y personalizar tu sitio
                          <br />
                          para organizar tus productos.
                        </b>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productos;
